/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const PAGINATION = {
  INVOICES_PER_PAGE: 9,
  ADDRESS_COUNTERS_PER_PAGE: 5,
  MULTIMEDIA_ADDRESSES_PER_PAGE: 5,
  METER_READINGS_PER_PAGE: 10,
  CONSUMPTION_ADDRESSES_PER_PAGE: 5,
};
