/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { ReactNode, useEffect, useState } from 'react';
import { Col, Row, Text, Title } from '../../../components/ui';
import BasicOfferImage from '../../../assets/images/energy-offer-basic.png';
import BasicOfferLogo from '../../../assets/images/energy-offer-basic-logo.png';
import HydroOfferImage from '../../../assets/images/energy-offer-hydro.png';
import HydroOfferLogo from '../../../assets/images/energy-offer-hydro-logo.png';
import HydroPlusOfferImage from '../../../assets/images/energy-offer-hydro-plus.png';
import HydroPlusOfferLogo from '../../../assets/images/energy-offer-hydro-plus-logo.png';
import NatureOfferImage from '../../../assets/images/energy-offer-nature.png';
import NatureOfferLogo from '../../../assets/images/energy-offer-nature-logo.png';
import NaturePlusOfferImage from '../../../assets/images/energy-offer-nature-plus.png';
import NaturePlusOfferLogo from '../../../assets/images/energy-offer-nature-plus-logo.png';
import styles from './EnergyOffers.module.scss';

type OfferProps = {
    type: 'BASIC' | 'HYDRO' | 'HYDRO_PLUS' | 'NATURE' | 'NATURE_PLUS' | 'SIE BASIC' | 'ENERGIE ROMANDE' | 'SIE NATURE +' | 'ENERGIE SUISSE' | 'SIE FORCE HYDRO EU' | 'SIE FORCE HYDRO +' | 'SIE PERSONNALISABLE' | 'SIE NATURE';
};

type OfferContent = {
  image?: string;
  logo?: string;
  text?: ReactNode;
  title?: string;
}

const Offer = ({ type }: OfferProps) => {
  const [content, setContent] = useState<OfferContent>({});

  useEffect(() => {
    switch (type) {
      case 'BASIC':
      case 'SIE BASIC':
        setContent({
          image: BasicOfferImage,
          logo: BasicOfferLogo,
          title: 'SIE Basic',
          text: (
            <Text size="large">
              En choisissant SIE Basic vous faites le choix d’une énergie à prix avantageux, <strong className="bold">quasiment exempte de CO<sub>2</sub>.</strong>
            </Text>
          ),
        });
        break;
      case 'HYDRO':
      case 'SIE FORCE HYDRO EU':
        setContent({
          image: HydroOfferImage,
          logo: HydroOfferLogo,
          title: 'SIE Force Hydro',
          text: (
            <Text size="large">
              En choisissant SIE Force Hydro, vous faites le choix d’une énergie <strong className="bold">100 % hydraulique, renouvelable et de provenance européenne.</strong>
            </Text>
          ),
        });
        break;
      case 'HYDRO_PLUS':
      case 'SIE FORCE HYDRO +':
        setContent({
          image: HydroPlusOfferImage,
          logo: HydroPlusOfferLogo,
          title: 'SIE Force Hydro +',
          text: (
            <Text size="large">
              En choisissant SIE Force Hydro +, vous faites le choix d’une énergie <strong className="bold">100 % hydraulique, renouvelable et suisse de surcroît.</strong>
            </Text>
          ),
        });
        break;
      case 'NATURE':
      case 'ENERGIE SUISSE':
      case 'SIE NATURE':
        setContent({
          image: NatureOfferImage,
          logo: NatureOfferLogo,
          title: 'SIE Nature',
          text: (
            <Text size="large">
              Avec SIE Nature, vous bénéficiez d’une électricité 100 % hydraulique, suisse de surcroît.
              En choisissant SIE Nature, vous faites le choix d’une énergie renouvelable, témoin de votre engagement durable.
            </Text>
          ),
        });
        break;
      case 'NATURE_PLUS':
      case 'SIE NATURE +':
      case 'ENERGIE ROMANDE':
        setContent({
          image: NaturePlusOfferImage,
          logo: NaturePlusOfferLogo,
          title: 'SIE Nature +',
          text: (
            <Text size="large">
              Avec SIE Nature +, vous bénéficiez d’un mix énergétique composé d’une énergie issue à 50 % de l’hydraulique suisse et à 50 % du photovoltaïque suisse.
              En choisissant SIE Nature +, vous faites le choix d’investir dans les nouvelles énergies renouvelables.
            </Text>
          ),
        });
        break;
      default:
        break;
    }
  }, [type]);
  return (
    <Row
      align="stretch" className={styles.offer}
      justify="start">
      <Col md={5} span={12}>
        <img alt="Offer" className={styles.energyOfferImage} src={content.image} />
      </Col>
      <Col md={7} span={12}>
        <Row className={styles.energyOfferContent}>
          <Col span={12}>
            <Title className={styles.offerTitle} level={2}>{content.title}</Title>
            {content.text}
          </Col>
          <Col className={styles.energyOfferLogoWrapper} span={12}>
            <img alt="Offer logo" className={styles.energyOfferLogo} src={content.logo} />
          </Col>
        </Row>
      </Col>
    </Row>);
};

export default Offer;
