/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import axios from "axios";
import { fetchMultimediaOfferApi } from "../../../../api/multimedia.api";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastError } from "../../../../services";
import { signOut } from "../../../auth/auth.actions";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./myMultimediaOffer.actionTypes";

export const fetchMyMultimediaOffer =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_MULTIMEDIA_OFFER_START });

      const response = await fetchMultimediaOfferApi(id);

      dispatch({
        type: types.FETCH_MULTIMEDIA_OFFER_SUCCESS,
        payload: {
          multimediaOffer: response.data.data.multimediaInfoDTOs, // TODO: API_CHANGES oto will be per dto
          externalClient: response.data.data.externalClient,
          oto: response.data.data.oto, // TODO: API_CHANGES oto is not here
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_MULTIMEDIA_OFFER_FAILURE });
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
    }
  };
