/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const FETCH_METER_READINGS_DATA_START =
  "METER_READINGS::FETCH_METER_READINGS_DATA_START";
export const FETCH_METER_READINGS_DATA_SUCCESS =
  "METER_READINGS::FETCH_METER_READINGS_DATA_SUCCESS";
export const FETCH_METER_READINGS_DATA_FAILURE =
  "METER_READINGS::FETCH_METER_READINGS_DATA_FAILURE";

export const FETCH_COUNTERS_START = "METER_READINGS::FETCH_COUNTERS_START";
export const FETCH_COUNTERS_SUCCESS = "METER_READINGS::FETCH_COUNTERS_SUCCESS";
export const FETCH_COUNTERS_FAILURE = "METER_READINGS::FETCH_COUNTERS_FAILURE";
export const CLEAR_COUNTERS = "METER_READINGS::CLEAR_COUNTERS";

export const FETCH_METER_READINGS_HISTORY_START =
  "METER_READINGS::FETCH_METER_READINGS_HISTORY_START";
export const FETCH_METER_READINGS_HISTORY_SUCCESS =
  "METER_READINGS::FETCH_METER_READINGS_HISTORY_SUCCESS";
export const FETCH_METER_READINGS_HISTORY_FAILURE =
  "METER_READINGS::FETCH_METER_READINGS_HISTORY_FAILURE";

export const POST_COUNTER_START = "METER_READINGS::POST_COUNTER_START";
export const POST_COUNTER_SUCCESS = "METER_READINGS::POST_COUNTER_SUCCESS";
export const POST_COUNTER_FAILURE = "METER_READINGS::POST_COUNTER_FAILURE";

export const ALL_ADDRESSES_UNCHECKED =
  "METER_READINGS::ALL_ADDRESSES_UNCHECKED";
