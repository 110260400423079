/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { AppState } from '../../redux/store';

export const userSelector = (state: AppState) => state.auth['user'];
export const myProfileSelector = (state: AppState) => state.auth['myProfile'];
export const electricityOfferSelector = (state: AppState) => state.auth['electricityOffer'];
export const invoicesAddressesSelector = (state: AppState) => state.auth['invoicesAddresses'];
export const receptionModeSelector = (state: AppState) => state.auth['receptionMode'];
export const isFetchingAuthSelector = (state: AppState) => state.auth['isFetching'];
export const isFetchingReceptionModeSelector = (state: AppState) => state.auth['isFetchingReceptionMode'];
export const isSubmittingAuthSelector = (state: AppState) => state.auth['isSubmitting'];
