/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { MouseEventHandler, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Link.module.scss";

const cx = classNames.bind(styles);

type LinkProps = {
  href?: string;
  target?: "_self" | "_blank" | "_parent" | "_top" | "";
  to?: string;
  align?: "left" | "center" | "right";
  isLight?: boolean;
  isSie?: boolean;
  isTvt?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  children?: ReactNode;
};

const Link = ({
  href = "",
  target = "",
  to = "",
  align = "left",
  isLight = false,
  isSie = false,
  isTvt = false,
  onClick = () => {},
  className = "",
  children,
}: LinkProps) =>
  to ? (
    <RouterLink
      className={cx(
        styles.link,
        styles[align],
        { [styles.sieLink]: isSie, [styles.tvtLink]: isTvt },
        className
      )}
      target={target || "_self"}
      to={to}
      onClick={onClick}
    >
      {children}
    </RouterLink>
  ) : (
    <a
      className={cx(
        styles.link,
        {
          [styles.light]: isLight,
          [styles.sieLink]: isSie,
          [styles.tvtLink]: isTvt,
        },
        className
      )}
      href={href}
      target={target || "_blank"}
      onClick={onClick}
    >
      {children}
    </a>
  );

export default Link;
