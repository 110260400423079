/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import * as types from "./auth.actionTypes";
import { AppAction } from "../../redux/actionType";

const initialState = {
  user: null,
  myProfile: null,
  electricityOffer: null,
  invoicesAddresses: null,
  receptionMode: null,
  isFetching: false,
  isFetchingReceptionMode: false,
  isSubmitting: false,
};
// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_USER_START:
    case types.FETCH_MY_PROFILE_DATA_START:
    case types.FETCH_INVOICES_ADDRESSES_START:
    case types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_START:
      return {
        ...state,
        isFetchingReceptionMode: true,
      };
    case types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_SUCCESS:
      return {
        ...state,
        receptionMode: action.payload,
        isFetchingReceptionMode: false,
      };
    case types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_SUCCESS:
      return {
        ...state,
        electricityOffer: action.payload,
        isFetching: false,
      };
    case types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_FAILURE:
      return {
        ...state,
        isFetchingReceptionMode: false,
      };
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetching: false,
      };
    case types.FETCH_MY_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        myProfile: action.payload,
        isFetching: false,
      };
    case types.FETCH_MY_PROFILE_DATA_FAILURE:
      return {
        ...state,
        myProfile: null,
        isFetching: false,
      };
    case types.FETCH_USER_FAILURE:
      return {
        ...state,
        user: null,
        isFetching: false,
      };
    case types.FETCH_INVOICES_ADDRESSES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCH_INVOICES_ADDRESSES_SUCCESS:
      return {
        ...state,
        invoicesAddresses: action.payload,
        isFetching: false,
      };
    case types.UPDATE_MY_PROFILE_DATA_START:
    case types.UPDATE_EMAIL_START:
    case types.UPDATE_PASSWORD_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.UPDATE_MY_PROFILE_DATA_SUCCESS:
    case types.UPDATE_PASSWORD_SUCCESS:
    case types.UPDATE_MY_PROFILE_DATA_FAILURE:
    case types.UPDATE_EMAIL_FAILURE:
    case types.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return { ...state };
  }
};
