/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable indent */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
import { useEffect, useState } from "react";
import { SearchIcon } from "../../../assets/icons";
import CoaxImage from "../../../assets/images/coax.png";
import FiberImage from "../../../assets/images/fiber.png";
import SunriseMomentsImage from "../../../assets/images/sunrise-moments.png";
import { PAGES } from "../../../common/constants/common";
import { PAGINATION } from "../../../common/constants/pagination";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import trackHistory from "../../../common/helpers/trackHistory";
import {
  Button,
  Col,
  Input,
  Link,
  Loader,
  Row,
  Separator,
  StatusLabel,
  Table,
  Text,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { userSelector } from "../../auth/auth.selectors";
import styles from "./MyMultimediaOffer.module.scss";
import MyMultimediaOfferCarousel from "./MyMultimediaOfferCarousel";
import { fetchMyMultimediaOffer } from "./service/myMultimediaOffer.actions";
import {
  INTERNET_COAXAL,
  INTERNET_OPTIQ,
} from "./service/myMultimediaOffer.constants";
import {
  externalClientSelector,
  isFetchingMyMultimediaOfferSelector,
  myMultimediaOfferSelector,
  otoSelector,
} from "./service/myMultimediaOffer.selectors";

const MyMultimediaOffer = () => {
  const location = window.location.href;
  const dispatch = useAppDispatch();
  const { scenario } = useAppSelector(userSelector);
  const isFetching = useAppSelector(isFetchingMyMultimediaOfferSelector);
  const addresses = useAppSelector(myMultimediaOfferSelector);
  const externalClient = useAppSelector(externalClientSelector);
  const oto = useAppSelector(otoSelector);
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState({} as any);
  const internetCoaxal = selectedAddress?.internetCoaxal;
  const internetOptiq = selectedAddress?.internetOptiq;
  const [offerText, setOfferText] = useState("");
  const [blockTwoImage, setBlockTwoImage] = useState("");
  const [blockTwoText, setBlockTwoText] = useState("");
  const [optiqName, setOptiqName] = useState("");
  const [coaxName, setCoaxName] = useState("");
  // TODO: set isMultimediaPageReady to false if it needs to be hidden
  const [isMultimediaPageReady, setIsMultimediaPageReady] = useState(false);

  useEffect(
    () => () => {
      trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
      sessionStorage.removeItem("clicks");
    },
    []
  );

  const addressesToShow = addresses
    .slice(
      0,
      showAllAddresses
        ? addresses.length
        : PAGINATION.MULTIMEDIA_ADDRESSES_PER_PAGE
    )
    .filter((address) =>
      address.address?.toLowerCase().includes(searchValue.trim().toLowerCase())
    );

  const onAddressSelect = (event) => {
    trackClickHandler();
    const objektId = event.target.value;
    const newSelectedAddress = addressesToShow.find(
      (address) => address.objektId === +objektId
    );
    if (newSelectedAddress) {
      setSelectedAddress(newSelectedAddress);
    }
  };

  const remainingAddressess =
    addresses.length - PAGINATION.MULTIMEDIA_ADDRESSES_PER_PAGE;

  // UNCOMMENT CODE ALL THE WAY TO RETURN STATEMENT WHEN THE PAGE IS READY FOR GO LIVE

  const renderConnectionsBlockBottomText = () => {
    if (
      internetOptiq === INTERNET_OPTIQ.ENABLED &&
      (internetCoaxal === INTERNET_COAXAL.OPEN ||
        internetCoaxal === INTERNET_COAXAL.LEADED)
    ) {
      return (
        <Text align="center">
          Vous possédez aussi un raccordement coaxial plombé. Si vous souhaitez
          l’utiliser en lieu et place de votre connexion fibre optique, merci
          de&nbsp;
          <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
            nous contacter
          </Link>
          .
        </Text>
      );
    }
    if (
      internetOptiq === INTERNET_OPTIQ.ENABLED &&
      internetCoaxal === INTERNET_COAXAL.UNAVAILABLE
    ) {
      return (
        <Text align="center">
          Vous n&apos;avez pas de raccordement coaxial actuellement. Si vous
          souhaitez mettre en place un raccordement coaxial en complément de
          votre connexion fibre optique, merci de&nbsp;
          <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
            nous contacter
          </Link>
          .
        </Text>
      );
    }
    if (
      internetOptiq === INTERNET_OPTIQ.AVAILABLE &&
      internetCoaxal === INTERNET_COAXAL.LEADED
    ) {
      return (
        <>
          <Text align="center">
            La fibre est disponible chez vous :{" "}
            <Link to={PAGES.CONTACT_AND_FORMS}>&nbsp;Contactez-nous</Link>
            &nbsp;pour passer à la fibre.
          </Text>
          <Separator size="small" />
          <Text>
            Faire une{" "}
            <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
              demande de mise hors service de votre raccordement coaxial
            </Link>
            .
          </Text>
        </>
      );
    }

    if (
      internetOptiq === INTERNET_OPTIQ.AVAILABLE &&
      internetCoaxal === INTERNET_COAXAL.OPEN
    ) {
      return (
        <>
          <Text align="center">
            La fibre est disponible chez vous :{" "}
            <Link to={PAGES.CONTACT_AND_FORMS}>&nbsp;Contactez-nous</Link>
            &nbsp;pour passer à la fibre.
          </Text>
          <Separator size="small" />
          <Text>
            Faire une{" "}
            <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
              demande de mise hors service de votre raccordement coaxial
            </Link>
            .
          </Text>
        </>
      );
    }

    if (
      internetOptiq === INTERNET_OPTIQ.AVAILABLE &&
      (internetCoaxal === INTERNET_COAXAL.LEADED ||
        internetCoaxal === INTERNET_COAXAL.UNAVAILABLE)
    ) {
      return (
        <Text align="center">
          La fibre est disponible chez vous :{" "}
          <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
            &nbsp;Contactez-nous
          </Link>
          &nbsp;pour passer à la fibre.
        </Text>
      );
    }

    if (
      internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
      (internetCoaxal === INTERNET_COAXAL.OPEN ||
        internetCoaxal === INTERNET_COAXAL.LEADED)
    ) {
      return (
        <>
          <Text>
            Vérifier si vous êtes{" "}
            <Link
              href="https://www.sie.ch/multimedia/fibre-optique-256"
              onClick={trackClickHandler}
            >
              éligible à la fibre
            </Link>
            .
          </Text>
          <Separator size="small" />
          <Text>
            Faire une{" "}
            <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
              demande de mise hors service
            </Link>
            .
          </Text>
        </>
      );
    }

    if (
      (internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.UNAVAILABLE) ||
      (!internetOptiq && !internetCoaxal)
    ) {
      return (
        <>
          <Text>
            Vérifier si vous êtes{" "}
            <Link
              href="https://www.sie.ch/multimedia/fibre-optique-256"
              onClick={trackClickHandler}
            >
              éligible à la fibre
            </Link>
            .
          </Text>
          <Separator size="small" />
          <Text>
            <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
              Contactez-nous
            </Link>{" "}
            pour plus d’informations.
          </Text>
        </>
      );
    }

    return null;
  };

  const renderFindOutMoreText = () => {
    if (
      internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
      internetCoaxal !== INTERNET_COAXAL.UNAVAILABLE
    ) {
      return (
        <Link
          href="https://www.sie.ch/multimedia/reseau-multimedia-263"
          onClick={trackClickHandler}
        >
          En savoir plusa
        </Link>
      );
    }

    if (!internetOptiq && !internetCoaxal) {
      return null;
    }

    if (
      internetOptiq !== INTERNET_OPTIQ.UNAVAILABLE &&
      internetCoaxal !== INTERNET_COAXAL.UNAVAILABLE
    ) {
      return (
        <Link
          href="https://www.sie.ch/multimedia/reseau-multimedia-256"
          onClick={trackClickHandler}
        >
          En savoir plus
        </Link>
      );
    }

    return null;
  };

  const setInternetOffer = () => {
    if (
      internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
      internetCoaxal === INTERNET_COAXAL.UNAVAILABLE
    ) {
      setOfferText("");
    } else if (
      (internetOptiq !== INTERNET_OPTIQ.ENABLED &&
        internetCoaxal === INTERNET_COAXAL.OPEN) ||
      (internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.LEADED)
    ) {
      setOfferText(
        "Connexion coaxiale. Débit maximum théorique jusqu’à 1 Gbit/s."
      );
    } else if (!internetOptiq && !internetCoaxal) {
      return null;
    } else {
      setOfferText(
        "Connexion fibre optique. Débit maximum théorique jusqu’à 10 Gbit/s."
      );
    }
    return null;
  };

  const setBlockTwoOffer = () => {
    if (
      (internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.UNAVAILABLE) ||
      (!internetOptiq && !internetCoaxal)
    ) {
      setBlockTwoImage(FiberImage);
      setBlockTwoText("");
    } else if (
      (internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.OPEN) ||
      (internetOptiq === INTERNET_OPTIQ.UNAVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.LEADED) ||
      (internetOptiq === INTERNET_OPTIQ.AVAILABLE &&
        internetCoaxal === INTERNET_COAXAL.OPEN)
    ) {
      setBlockTwoImage(CoaxImage);
      setBlockTwoText("Installation numéro: ");
    } else {
      setBlockTwoImage(FiberImage);
      setBlockTwoText("Numéro OTO : ");
    }
  };

  useEffect(() => {
    dispatch(fetchMyMultimediaOffer());
  }, []);

  useEffect(() => {
    setInternetOffer();
    setBlockTwoOffer();

    if (selectedAddress?.internetOptiq) {
      setOptiqName(selectedAddress?.internetOptiq);
    } else {
      setOptiqName("Indisponible");
    }

    if (selectedAddress?.internetCoaxal) {
      setCoaxName(selectedAddress?.internetCoaxal);
    } else {
      setCoaxName("Indisponible");
    }
  }, [internetCoaxal, internetOptiq]);

  useEffect(() => {
    setSelectedAddress(addresses[0]);
  }, [addresses]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isMultimediaPageReady) {
    return (
      <>
        <Title>Mon offre multimédia</Title>
        <Row
          wrapForMobile
          align="stretch"
          className={styles.connectionBlocks}
          justify="space-between"
          wrap={false}
        >
          <Text size="large">
            Votre page multimédia sera prochainement disponible. Vous pourrez
            accéder à vos offres en cours et installations techniques disponibles.
          </Text>
        </Row>
      </>
    );
  }

  return (
    <>
      <Title>Mon offre multimédia</Title>
      <Separator />
      {scenario !== 1 && (
        <Row ignorePageContentPadding align="start" direction="column">
          <Col hasPageContentOffset>
            <Title isBoxTitle usePrimaryColor level={3}>
              Sélectionner vos raccordements
            </Title>
          </Col>
          <Col hasPageContentOffset lg={7} md={6} span={12}>
            <Input
              placeholder="Recherche par adresse"
              prefix={<SearchIcon />}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={trackClickHandler}
            />
          </Col>
          <Col hasPageContentOffset span={12}>
            <Separator size="large" />
            <Table
              selectableRows
              columns={[
                { dataIndex: "address", title: "Adresse" },
                {
                  dataIndex: "internetCoaxial",
                  title: "Internet : coaxial (HFC)",
                },
                {
                  dataIndex: "internetFibreOptique",
                  title: "Internet : fibre optique",
                },
              ]}
              data={addressesToShow.map((address) => ({
                key: address.objektId,
                address: address.address,
                internetCoaxial: (
                  <StatusLabel status={address.internetCoaxal} />
                ),
                internetFibreOptique: (
                  <StatusLabel status={address.internetOptiq} />
                ),
              }))}
              defaultSelectedRow={0}
              id="myMultimediaTable"
              onRowSelect={onAddressSelect}
            />
            {!showAllAddresses &&
              addresses?.length > PAGINATION.MULTIMEDIA_ADDRESSES_PER_PAGE && (
                <Button
                  buttonStyle="linkButton"
                  className={styles.displayAllButton}
                  onClick={() => {
                    trackClickHandler();
                    setShowAllAddresses(true);
                  }}
                >
                  Afficher tout ({remainingAddressess})
                </Button>
              )}
          </Col>
        </Row>
      )}

      <Separator size="large" />
      <Row
        wrapForMobile
        align="stretch"
        className={styles.connectionBlocks}
        justify="space-between"
        wrap={false}
      >
        {/* THIS IS TEMPORARY TEXT UNTIL THIS PAGE IS READY */}
        {/* <Text size="large">
          Votre page multimédia sera prochainement disponible. Vous pourrez
          accéder à vos offres en cours et installations techniques disponibles.
        </Text> */}
        {/* CONNECTION BLOCK */}
        <Col isContentBox className={styles.connectionBox} md={5} span={12}>
          <Title isBoxTitle usePrimaryColor align="center" level={3}>
            Mes raccordements
          </Title>
          <Title isBoxTitle usePrimaryColor align="center" level={3}>
            FO {String(optiqName).toLowerCase()} / coax{" "}
            {String(coaxName).toLowerCase()}
          </Title>
          <Separator size="small" />
          <img
            alt="coax or fiber"
            className={styles.fiberImage}
            src={blockTwoImage}
          />
          <Separator size="small" />
          <Text>
            {blockTwoText} {oto}
          </Text>
          <Separator size="small" />
          <Text align="center">{offerText}</Text>
          <Separator size="small" />
          {renderFindOutMoreText()}
          <Separator size="small" />
          {renderConnectionsBlockBottomText()}
        </Col>

        {/* MULTIMEDIA NETWORK BLOCK */}
        <Col isContentBox md={7} span={12}>
          {scenario !== 4 && (
            <>
              <Title
                isBoxTitle
                usePrimaryColor
                className={styles.multimediaNetworkTitle}
                level={3}
              >
                Raccord au réseau multimédia
              </Title>
              <Title usePrimaryColor level={1}>
                <span className={styles.lightText}>Dès</span> 29,90&nbsp;
                <span className={styles.lightText}>CHF / mois</span>
              </Title>

              {internetCoaxal === INTERNET_COAXAL.LEADED && scenario === 1 && (
                <>
                  <Text align="center">
                    Le raccordement multimédia 3 en 1 vous permet de bénéficier
                    des prestations suivantes :
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">Offre de base TV numérique</Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Basic Internet 10{" "}
                    <span className={styles.offerMb}>Mb/s</span>
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Basic Phone, le raccordement gratuit au réseau fixe
                    <br />
                    (seules les communications sont payantes)
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Faire une{" "}
                    <Link to={PAGES.CONTACT_AND_FORMS}>
                      demande de mise en service
                    </Link>
                    .
                  </Text>
                </>
              )}

              {(internetCoaxal === INTERNET_COAXAL.UNAVAILABLE ||
                !internetCoaxal) && (
                <>
                  <Text align="center">
                    Le raccordement multimédia 3 en 1 vous permet de bénéficier
                    des prestations suivantes :
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">Offre de base TV numérique</Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Basic Internet 10{" "}
                    <span className={styles.offerMb}>Mb/s</span>
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Basic Phone, le raccordement gratuit au réseau fixe
                    <br />
                    (seules les communications sont payantes)
                  </Text>
                  <Separator className={styles.offerTextLineSeparator} />
                  <Text align="center">
                    Offre non disponible à cette adresse. Nous sommes à
                    disposition pour{" "}
                    <Link
                      to={PAGES.CONTACT_AND_FORMS}
                      onClick={trackClickHandler}
                    >
                      plus d&apos;informations
                    </Link>
                    .
                  </Text>
                </>
              )}

              {internetCoaxal === INTERNET_COAXAL.LEADED &&
                (scenario === 2 || scenario === 3) && (
                  <>
                    <Text align="center">
                      Le raccordement multimédia 3 en 1 vous permet de
                      bénéficier des prestations suivantes :
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">Offre de base TV numérique</Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">
                      Basic Internet 10{" "}
                      <span className={styles.offerMb}>Mb/s</span>
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">
                      Basic Phone, le raccordement gratuit au réseau fixe
                      <br />
                      (seules les communications sont payantes)
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">
                      Faire une{" "}
                      <Link to={PAGES.CONTACT_AND_FORMS}>
                        demande de mise en service
                      </Link>
                      .
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">
                      Pour aller plus vite ! Bénéficiez des offres de notre
                      partenaire Sunrise !
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text align="center">Plus de 275 chaînes TV</Text>
                    <Separator className={styles.offerTextLineSeparator} />
                    <Text>
                      Internet ultra-haut débit, atteignant 1Gbit/s En savoir
                      plus concernant{" "}
                      <Link
                        className={styles.alignCenter}
                        href="https://www.sie.ch/multimedia/commander-votre-offre/"
                        onClick={trackClickHandler}
                      >
                        {" "}
                        les offres de notre partenaire
                      </Link>
                      .
                    </Text>
                    <Separator className={styles.offerTextLineSeparator} />
                  </>
                )}

              {internetCoaxal === INTERNET_COAXAL.OPEN && (
                <>
                  <Text align="center">
                    Pour aller plus vite ! Bénéficiez des offres de notre
                    partenaire Sunrise !
                  </Text>
                  <Separator
                    className={styles.offerTextLineSeparator}
                    size="large"
                  />
                  <Text align="center">Plus de 275 chaînes TV</Text>
                  <Separator
                    className={styles.offerTextLineSeparator}
                    size="large"
                  />
                  <Text align="center">
                    Internet ultra-haut débit, atteignant 1Gbit/s
                  </Text>
                  <Separator
                    className={styles.offerTextLineSeparator}
                    size="large"
                  />
                  <Text>
                    En savoir plus concernant{" "}
                    <Link
                      className={styles.alignCenter}
                      href="https://www.sie.ch/multimedia/commander-votre-offre/"
                      onClick={trackClickHandler}
                    >
                      {" "}
                      les offres de notre partenaire
                    </Link>
                    .
                  </Text>
                </>
              )}
            </>
          )}

          {scenario === 4 && (
            <>
              <Title isBoxTitle usePrimaryColor level={3}>
                Votre abonnement chez notre partenaire
              </Title>
              <Text>Nom partenaire: {externalClient}</Text>
              <Separator size="large" />
              <Text align="center">
                <Link
                  href="https://www.sunrise.ch/"
                  onClick={trackClickHandler}
                >
                  Vous trouverez toutes les informations concernant votre compte
                  client dans le portail de notre partenaire
                </Link>
                .
              </Text>
            </>
          )}
        </Col>
      </Row>

      <Separator size="large" />
      <MyMultimediaOfferCarousel />

      {/* SUNRISE BLOCK */}
      {scenario === 4 && (
        <Row justify="center">
          <Col
            isContentBox
            className={styles.sunriseMomentsContentBox}
            span={12}
          >
            <Row className={styles.sunriseMomentsContent}>
              <Col lg={6} span={12}>
                <div className={styles.sunriseMomentsBlockContent}>
                  <Title isBoxTitle usePrimaryColor level={3}>
                    Programme de fidélité: Sunrise Moments
                  </Title>
                  <Text>
                    <span className={styles.bold}>Priority Tickets:</span>{" "}
                    billets de concert disponibles 48 heures avant le lancement
                    officiel des préventes
                  </Text>
                  <Text>
                    <span className={styles.bold}>Rabais exclusifs:</span>{" "}
                    jusqu’à 25% de rabais sur les billets de nombreux festivals
                    de musique
                  </Text>
                  <Text>
                    <span className={styles.bold}>Fast Lane:</span> entrée
                    séparée et pas d’attente à la plupart des concerts
                  </Text>
                  <Text>
                    <span className={styles.bold}>Accès VIP:</span> lounges
                    exclusifs lors des concerts et des festivals
                  </Text>
                  <Text>
                    <span className={styles.bold}>Last Minute Tickets:</span>{" "}
                    billets pour des concerts et des festivals déjà complets
                  </Text>
                  <Text>
                    <span className={styles.bold}>Billets gratuits:</span>{" "}
                    concours réguliers permettant de gagner des billets pour des
                    événements musicaux ou sportifs
                  </Text>
                </div>
              </Col>
              <Col lg={6} span={12}>
                <div className={styles.sunriseMomentsImageWrapper}>
                  <img
                    alt="sunrise moments"
                    className={styles.sunriseMomentsImage}
                    src={SunriseMomentsImage}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MyMultimediaOffer;
