/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import axios, { AxiosResponse } from "axios";
import {
  fetchConsumptionApi,
  fetchDashboardComplexityApi,
  fetchDashboardElectricityContractApi,
  fetchDashboardLastInvoiceApi,
  fetchDashboardMultimediaContractApi,
  subscribeToNewsletterApi,
} from "../../../../api/dashboard.api";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastError, toastSuccess } from "../../../../services";
import { signOut } from "../../../auth/auth.actions";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./dashboard.actionTypes";
import {
  fetchDashboardComplexityResponse,
  fetchDashboardConsumptionResponse,
  fetchDashboardLastInvoiceResponse,
  fetchElectricityContractResponse,
  fetchMultimediaContractResponse,
  subscribeToNewsletterRequest,
} from "./dashboard.types";

export const fetchDashboardData =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id, scenario } = userSelector(getState());
      dispatch({ type: types.FETCH_DASHBOARD_DATA_START });

      const responseComplexity: AxiosResponse<fetchDashboardComplexityResponse> =
        await fetchDashboardComplexityApi(id);

      let responseConsumption: AxiosResponse<fetchDashboardConsumptionResponse>;
      let responseMultimediaContract: AxiosResponse<fetchMultimediaContractResponse>;
      let responseElectricityContract: AxiosResponse<fetchElectricityContractResponse>;
      let consumptionData: any = null;
      let multimediaContractData: any = null;
      let electricityContractData: any = null;
      // complex and simple dashboard
      const responseLastInvoice: AxiosResponse<fetchDashboardLastInvoiceResponse> =
        await fetchDashboardLastInvoiceApi(id);

      // simple dashboard
      if (!responseComplexity.data.data.complexAddress) {
        responseElectricityContract =
          await fetchDashboardElectricityContractApi(id);
        electricityContractData = responseElectricityContract.data.data;
        dispatch({
          type: types.FETCH_LAST_INVOICE_AND_ELECTRICITY_CONTRACT_SUCCESS,
          payload: {
            lastInvoice: responseLastInvoice.data.data,
            electricityContract: electricityContractData,
          },
        });
        responseMultimediaContract = await fetchDashboardMultimediaContractApi(
          id
        );
        multimediaContractData = responseMultimediaContract.data.data;
      } else {
        dispatch({
          type: types.FETCH_LAST_INVOICE_AND_ELECTRICITY_CONTRACT_SUCCESS,
          payload: {
            lastInvoice: responseLastInvoice.data.data,
            electricityContract: electricityContractData,
          },
        });
      }

      if (scenario !== 2) {
        responseConsumption = await fetchConsumptionApi(id);
        consumptionData = responseConsumption.data.data;
      }

      dispatch({
        type: types.FETCH_DASHBOARD_DATA_SUCCESS,
        payload: {
          isComplex: responseComplexity.data.data.complexAddress,
          consumption: consumptionData,
          multimediaContract: multimediaContractData,
          electricityContract: electricityContractData,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_DASHBOARD_DATA_FAILURE });
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
    }
  };

export const subscribeToNewsletter =
  (data: subscribeToNewsletterRequest, resetForm: Function) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.SUBSCRIBE_TO_NEWSLETTER_START });

      await subscribeToNewsletterApi(id, data);

      dispatch({ type: types.SUBSCRIBE_TO_NEWSLETTER_SUCCESS });
      toastSuccess({ message: "Inscription à la newsletter réussie." });
      resetForm();
    } catch (error) {
      dispatch({ type: types.SUBSCRIBE_TO_NEWSLETTER_FAILURE });
      toastError({
        message:
          "Une erreur s'est produite lors de l'inscription à la newsletter",
      });
    }
  };
