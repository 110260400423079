/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import {
  NavigationContactAndFormsIcon,
  NavigationDashboardIcon,
  NavigationHelpAndFaqsIcon,
  NavigationMyBillsIcon,
  NavigationMyConsumptionIcon,
  NavigationMyElectricitySupplyIcon,
  NavigationMyMeterRatingsIcon,
  NavigationMyMultimediaOfferIcon,
} from "../../../assets/icons";
import {
  PAGES,
  PRIMARY_COLORS,
  SCENARIOS,
} from "../../../common/constants/common";

export const NAVIGATION_ITEMS = [
  {
    name: "Tableau de bord",
    pathname: PAGES.DASHBOARD,
    color: PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA,
    icon: React.createElement(NavigationDashboardIcon),
    hiddenForScenarios: [],
  },
  {
    name: "Mes factures",
    pathname: PAGES.MY_INVOICES,
    color: PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA,
    icon: React.createElement(NavigationMyBillsIcon),
    hiddenForScenarios: [],
  },
  {
    name: "Contact et formulaires",
    pathname: PAGES.CONTACT_AND_FORMS,
    color: PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA,
    icon: React.createElement(NavigationContactAndFormsIcon),
    separator: true,
    hiddenForScenarios: [],
  },
  {
    name: "Ma consommation",
    pathname: PAGES.MY_CONSUMPTION,
    color: PRIMARY_COLORS.ELECTRICITY,
    icon: React.createElement(NavigationMyConsumptionIcon),
    hiddenForScenarios: [SCENARIOS.SCENARIO_2],
  },
  {
    name: "Mes relevés de compteurs",
    pathname: PAGES.MY_METER_READINGS,
    color: PRIMARY_COLORS.ELECTRICITY,
    icon: React.createElement(NavigationMyMeterRatingsIcon),
    hiddenForScenarios: [SCENARIOS.SCENARIO_2],
  },
  {
    name: "Mon offre énergie",
    pathname: PAGES.MY_ENERGY_OFFER,
    color: PRIMARY_COLORS.ELECTRICITY,
    icon: React.createElement(NavigationMyElectricitySupplyIcon),
    separator: true,
    hiddenForScenarios: [SCENARIOS.SCENARIO_2],
  },
  {
    name: "Mon offre multimédia",
    pathname: PAGES.MY_MULTIMEDIA_OFFER,
    color: PRIMARY_COLORS.MULTIMEDIA,
    icon: React.createElement(NavigationMyMultimediaOfferIcon),
    separator: true,
    hiddenForScenarios: [],
  },
  {
    name: "Aide et FAQ",
    pathname: PAGES.HELP_AND_FAQS,
    color: PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA,
    icon: React.createElement(NavigationHelpAndFaqsIcon),
    separator: true,
    hiddenForScenarios: [],
  },
];
