/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { SmallArrowIcon } from "../../../assets/icons";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import trackHistory from "../../../common/helpers/trackHistory";
import {
  Button,
  Col,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import { GROUPS } from "./groups";
import styles from "./HelpAndFaqs.module.scss";

const cx = classNames.bind(styles);

const HelpAndFaqs = () => {
  const location = window.location.href;
  const [currentOpenedQuestion, setCurrentOpenedQuestion] = useState("");

  const handleClickOnQuestion = (question: string) => {
    trackClickHandler();
    if (question === currentOpenedQuestion) {
      setCurrentOpenedQuestion("");
    } else {
      setCurrentOpenedQuestion(question);
    }
  };

  useEffect(
    () => () => {
      trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
      sessionStorage.removeItem("clicks");
    },
    []
  );

  return (
    <Row align="start" direction="column" justify="start">
      <Col>
        <Title>Aide et FAQ</Title>
      </Col>
      <Col span={12}>
        {GROUPS.map((group) => (
          <React.Fragment key={group.name}>
            <Title level={2}>{group.name}</Title>
            {group.questions.map((question) => (
              <article
                className={cx(styles.questionContainer, {
                  [styles.questionContainerActive]:
                    currentOpenedQuestion === question.text,
                })}
                key={question.text}
              >
                <Button
                  className={styles.questionButton}
                  prefix={
                    <SmallArrowIcon
                      className={cx(styles.questionArrowIcon, {
                        [styles.questionArrowIconActive]:
                          currentOpenedQuestion === question.text,
                      })}
                    />
                  }
                  onClick={() => handleClickOnQuestion(question.text)}
                >
                  <Title className={cx(styles.question)} level={3}>
                    {question.text}
                  </Title>
                </Button>
                {currentOpenedQuestion === question.text && (
                  <ul className={styles.answers}>
                    {question.answers.map((answer) => (
                      <li key={answer.text.toString()}>
                        <Text>
                          {answer.text.map((answerPart) => (
                            <React.Fragment key={answerPart.toString()}>
                              {answerPart}
                            </React.Fragment>
                          ))}
                        </Text>
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            ))}
          </React.Fragment>
        ))}
      </Col>
      <Separator />
    </Row>
  );
};

export default HelpAndFaqs;
