/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Input,
  Loader,
  Row,
  Separator,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  checkAdminConnection,
  deleteNewsletter,
  deleteUser,
  extractNewsletter,
  impersonateUser,
  resendVerificationEmail,
  retrieveUser,
} from "../admin.actions";
import {
  isAdminConnectionCheckedSelector,
  isFetchingUserSelector,
  isResendingVerificationEmailSelector,
  userSelector,
} from "../admin.selectors";
import { retrieveUserRequestData } from "../admin.types";
import styles from "./AdminDashboard.module.scss";

const AdminDashboard = () => {
  const [currentLoader, setCurrentLoader] = useState<
    | null
    | "IMPERSONATE_USER"
    | "DELETE_USER"
    | "EXTRACT_NEWSLETTER"
    | "DELETE_NEWSLETTER"
  >(null);
  const isAdminConnectionChecked = useAppSelector(
    isAdminConnectionCheckedSelector
  );
  const isFetchingUser = useAppSelector(isFetchingUserSelector);
  const isResendingVerificationEmail = useAppSelector(
    isResendingVerificationEmailSelector
  );
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
    },
    shouldUnregister: true,
  });

  const onRetrieveUser = (data: retrieveUserRequestData) => {
    dispatch(retrieveUser(data));
  };

  const onImpersonateUser = () => {
    setCurrentLoader("IMPERSONATE_USER");
    dispatch(
      impersonateUser({ userId: user.userId }, setError, setCurrentLoader)
    );
  };

  const onDeleteUser = () => {
    if (window.confirm("Confirmer la modification?")) {
      setCurrentLoader("DELETE_USER");
      dispatch(
        deleteUser({ userId: user.userId }, setError, () => {
          setCurrentLoader(null);
          reset();
        })
      );
    }
  };

  const onResendVerificationEmail = () => {
    if (window.confirm("Confirmer la modification?")) {
      dispatch(resendVerificationEmail({ userId: user.userId }));
    }
  };

  const onExtractNewsletter = () => {
    setCurrentLoader("EXTRACT_NEWSLETTER");
    dispatch(extractNewsletter(setCurrentLoader));
  };

  const onDeleteNewsletter = () => {
    setCurrentLoader("DELETE_NEWSLETTER");
    dispatch(deleteNewsletter(setCurrentLoader));
  };

  useEffect(() => {
    dispatch(checkAdminConnection());
  }, []);

  if (!isAdminConnectionChecked) {
    return <Loader type="auth" />;
  }

  return (
    <>
      <Row className={styles.userManagement} direction="column">
        <Col lg={5} span={12}>
          <Title align="left" className={styles.title} level={2}>
            Gestion des utilisateurs
          </Title>
        </Col>
        <Col isContentBox lg={5} span={12}>
          <Title align="center" level={3}>
            Veuillez entrer le client ID à traiter.{" "}
          </Title>
          <form
            className={styles.userForm}
            onSubmit={handleSubmit(onImpersonateUser)}
          >
            <Col md={6} span={12}>
              <Input
                {...register("userId", { required: true })}
                error={
                  (errors.userId?.type === "required" &&
                    "L´identifiant de l´utilisateur est requis") ||
                  (errors.userId?.type === "incorrectUserId" &&
                    "ID utilisateur incorrect ou utilisateur non enregistré")
                }
                type="number"
              />
            </Col>
            <Col md={6} span={12}>
              <Button
                className={styles.retrieveUserBtn}
                loading={isFetchingUser}
                type="submit"
                onClick={handleSubmit(onRetrieveUser)}
              >
                Récupérer les informations
              </Button>
            </Col>
          </form>
          <Separator />
          <Row className={styles.accountDetails}>
            <Col span={6}> Client ID </Col>
            <Col span={6}>{user ? user.userId : "-"}</Col>
            <Col span={6}> Nom de famille </Col>
            <Col span={6}>{user ? user.userName : "-"}</Col>
            <Col span={6}> Compte créé </Col>
            <Col span={6}>
              {
                /* eslint-disable-next-line */
                user ? (user.isCreated ? "Yes" : "No") : "-"
              }
            </Col>
            <Col span={6}> Adresse email utilisée </Col>
            <Col span={6}>{user ? user.email : "-"}</Col>
            <Col span={6}> Compte activé </Col>
            <Col span={6}>
              {
                /* eslint-disable-next-line */
                user ? (user.isVerified ? "Yes" : "No") : "-"
              }
            </Col>
          </Row>
          <Separator />
          <Row className={styles.buttons}>
            <Button
              className={styles.userButton}
              disabled={!user}
              loading={currentLoader === "IMPERSONATE_USER"}
              type="submit"
              onClick={onImpersonateUser}
            >
              Utiliser compte client
            </Button>
            <Button
              className={styles.userButton}
              disabled={!user || user.isVerified}
              loading={currentLoader === "IMPERSONATE_USER"}
              type="submit"
              onClick={onResendVerificationEmail}
            >
              Renvoyer l&apos;email d&apos;activation
            </Button>
            <Button
              className={styles.userButton}
              disabled={!user}
              loading={currentLoader === "DELETE_USER"}
              onClick={onDeleteUser}
            >
              Supprimer le compte utilisateur
            </Button>
          </Row>
        </Col>
      </Row>
      <Separator />
      <Row className={styles.newsletterManagement} direction="column">
        <Col lg={5} span={12}>
          <Title align="left" className={styles.title} level={2}>
            Gestion de la newsletter
          </Title>
        </Col>
        <Col isContentBox lg={5} span={12}>
          <Title level={3}>Liste des abonnnés pour la newsletter</Title>
          <Row className={styles.buttons}>
            <Button
              className={styles.newsletterButton}
              loading={currentLoader === "DELETE_NEWSLETTER"}
              onClick={onDeleteNewsletter}
            >
              Supprimer la liste des abonnés
            </Button>
            <Button
              className={styles.newsletterButton}
              loading={currentLoader === "EXTRACT_NEWSLETTER"}
              onClick={onExtractNewsletter}
            >
              Extraire la liste des abonnés
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
