/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { PAGES } from "../../../common/constants/common";
import { Link, Row, Separator, Text } from "../../../components/ui";
import Section from "./Section";
import styles from "./TermsOfService.module.scss";

const TermsOfService = () => (
  <Row align="start" className={styles.termsOfService} direction="column">
    <Section
      paragraphs={[
        "Les présentes Conditions d’utilisation règlent les conditions contractuelles entre le client et SIE et TVT Services concernant l’utilisation de l’Espace Client.",
      ]}
      title="Conditions d’utilisation de l’Espace Client SIE et TVT Services"
    />
    <Section
      paragraphs={[
        "SIE et TVT Services proposent différentes fonctionnalités à ses clients sur l’Espace Client. Les présentes Conditions d’utilisation règlent les conditions contractuelles entre le client et SIE et TVT Services concernant l’utilisation de l’Espace Client. En s’inscrivant sur l’Espace Client, le client accepte les présentes Conditions d’utilisation.",
      ]}
      title="1. Champ d’application"
    />
    <Section
      paragraphs={[
        "L’accès à l’Espace Client est en principe ouvert à tous les clients de SIE et TVT Services. L’accès à l’Espace Client ne constitue pas un droit.",
      ]}
      title="2. Droits d’accès"
    />
    <Section
      paragraphs={[
        "L’accès technique s’effectue sur Internet par le biais d’un fournisseur d’accès choisi par le client.",
        "Pour pouvoir utiliser l’Espace Client, un navigateur Internet standard et à jour doit être installé (dernière version des navigateurs Safari, Edge, Chrome, Firefox).",
        "A défaut, l’accès à l’Espace Client peut s’avérer impossible ou seulement partiel. SIE et TVT Services ne peuvent offrir aucune assistance en cas de problème technique quel qu’il soit, par exemple concernant le terminal du client ou la connexion Internet.",
      ]}
      title="3. Accès technique"
    />
    <Section
      paragraphs={[
        "Une fois qu’il s’est inscrit conformément à l’instruction en ligne, le client reçoit un lien d’activation par email. C’est seulement une seule fois que le compte est activé que l’utilisateur peut accéder à l’espace Client.",
        "L’utilisateur peut ensuite ouvrir sa session avec son identifiant et son mot de passe afin d’accéder à ses données. Celui qui se légitime sur l’Espace Client avec ses données de connexion, conformément au processus précité, est considéré par SIE et TVT Services comme autorisé à utiliser l’Espace Client et peut solliciter l’ensemble des prestations proposées. Il est notamment autorisé à effectuer des modifications ainsi que de consulter les documents mis à disposition sur l’Espace Client.",
      ]}
      title="4. Inscription en ligne"
    />
    <Section
      paragraphs={["L’utilisation de l’Espace Client est gratuit."]}
      title="5. Coût"
    />
    <Section
      paragraphs={[
        "Il est de la responsabilité du client de garder secrètes ses données de connexion et de les protéger contre toute utilisation abusive par des tiers non autorisés. Le client supporte l’ensemble des conséquences qui résultent d’une utilisation non autorisée des critères de légitimation. En cas de doute quant à la possibilité pour des tiers d’avoir eu connaissance des données de connexion, le mot de passe doit être immédiatement changé. Il est de la responsabilité du client de payer dans les délais impartis les factures reçues via l’Espace Client au moyen d’un système de paiement sélectionné. Le client doit saisir sans délai sur l’Espace Client les modifications de son adresse postale ou électronique, de son compte bancaire ainsi que d’autres données personnelles. Le client qui télécharge des documents de l’Espace Client sur son propre terminal doit veiller lui-même à la sécurité requise de ses données. SIE et TVT Services déclinent toute responsabilité dès lors qu’un document quitte les systèmes de SIE et TVT Services.",
      ]}
      title="6. Devoir et diligence du client"
    />
    <Section
      paragraphs={[
        "Lors de son inscription sur l’Espace Client avec un numéro de client portant sur plusieurs personnes (type groupe, couple ou colocation), les personnes au sein de cette même entité ont accès aux données de toutes les personnes dans le contrat, y compris les données sensibles (données de consommation, factures, etc…). Chaque personne possédant un contrat en commun peut s’inscrire sur l’Espace Client avec ses propres identifiants. Le client dispose d’un accès à ses propres données et, notamment, de la possibilité de modifier ses données de contact. De plus, le client a accès aux données notoires de toutes les personnes dans le même contrat (p. ex. adresse, no de téléphone), qu’elles soient toutes inscrites ou non sur l’Espace Client. Si des modifications sont nécessaires sur les membres figurant dans le contrat, il faut le signifier par écrit au par téléphone à notre Service Clientèle afin d’adapter les contrats et les accès.",
      ]}
      title="7. Clients ayant accès au même contrat"
    />
    <Section
      other={
        <ul>
          <li>
            <Text>
              Des mesures de sécurité insuffisantes sur le terminal du client
              peuvent faciliter l’accès illicite à ses données. Il n’est pas
              exclu qu’un tiers accède sans être repéré au terminal du client
              pendant l’utilisation de l’Espace Client. Aussi SIE et TVT
              Services recommandent instamment à ses clients de doter leurs
              terminaux de programmes de protection à jour et de les protéger à
              l’aide d’un mot de passe lors de l’utilisation d’un WLAN. Il est
              par ailleurs déconseillé d&apos;utiliser l&apos;Espace Client par
              le biais d&apos;un WLAN public, puisque dans ce cadre, les
              informations et les données transmises entre SIE et TVT Services,
              et le client par le biais d&apos;un réséau public, sont
              accessibles à tous.
            </Text>
          </li>
          <li>
            <Text>
              SIE et TVT Services n’ont aucune influence sur le fait que le
              fournisseur d’accès à Internet choisi par le client analyse le
              trafic des données, ni sur les modalités de cette analyse. Il est
              donc tout à fait possible de déterminer quand et avec qui
              l’utilisateur d’Internet est entré en contact.
            </Text>
          </li>
          <li>
            <Text>
              Après une certaine période d’inactivité sur l’Espace Client, la
              session en cours est automatiquement fermée pour des raisons de
              sécurité et une nouvelle session doit être ouverte.
            </Text>
          </li>
        </ul>
      }
      paragraphs={[
        "On accède à l’Espace Client via Internet. Aucune sécurité absolue ne peut être garantie, même si les mesures de sécurité sur le terminal sont conformes aux dernières avancées technologiques et scientifiques. SIE et TVT Services attirent notamment l’attention du client sur les risques suivants lors de l’utilisation de l’Espace Client.",
      ]}
      title="8. Informations en terme de sécurité"
    />
    <Section
      paragraphs={[
        "Les contenus des pages sur Espace Client sont établis avec le plus grand soin. Cependant, SIE et TVT Services ne peuvent garantir l’exactitude, ni l’exhaustivité des données transmises et mises à disposition. SIE et TVT Services ne peuvent en aucun cas garantir que laccès à l’Espace Client se déroulera sans interruption, ni erreurs, que les défaillances seront résolues, et qu’aucun cas, SIE et TVT Services ne sauraient être tenues responsables des dommages directs et indirects, notamment du manque à gagner, des dommages consécutifs au téléchargement ou des atteintes à la réputation.",
      ]}
      title="9. Responsabilité"
    />
    <Section
      paragraphs={[
        "SIE et TVT Services prennent toutes les mesures techniques et organisationnelles adéquates afin de protéger les données des clients contre les accès illicites ou tout autre traitement frauduleux des données. Les données et documents que le client consulte sur l’Espace Client proviennent des domaines et systèmes correspondants de SIE et TVT Services et restent visibles tant que le client est en ligne.",
      ]}
      title="10. Protection et sécurité des données"
    />
    <Section
      other={
        <Text>
          Des informations plus détaillées concernant la protection des données
          sont disponibles sur
          <Link isLight target="_blank" to={PAGES.DATA_PROTECTION}>
            &nbsp;protection des données
          </Link>
        </Text>
      }
    />
    <Section
      paragraphs={[
        "Si ils constatent des risques quels qu’ils soient pour la sécurité, SIE et TVT Services se réservent le droit de bloquer temporairement ou définitivement l’accès à l’Espace Client dans l’intérêt des clients et afin de protéger leurs données.",
      ]}
      title="11. Blocage de l’accès"
    />
    <Section
      paragraphs={[
        "SIE et TVT Services collectent, traitent et analysent des données de suivi à des fins d’optimisation de l’Espace Client et en vue d’établir des statistiques concernant son utilisation. Ces données de suivi ne permettent aucune déduction concernant les clients et se limitent à fournir des indications sur le comportement général des utilisateurs sur le site. Il s’agit de processus d’analyse anonymes visant à l’amélioration de l’Espace Client. Cela permet à SIE et TVT Services d’optimiser en permanence leur site Internet et de rendre la navigation plus conviviale. Les cookies (petits fichiers de texte dans lesquels un numéro d’identification est intégré) donnent la possibilité à SIE et TVT Services d’améliorer le service à la clientèle et de reconnaître les visiteurs qui ont déjà consulté leur site précédemment. La plupart des navigateurs Internet disposent de fonctions qui permettent de désactiver la réception automatique de cookies ou de supprimer des cookies déjà enregistrés. Si le client ne souhaite pas recevoir de cookies, il peut configurer le logiciel de navigation de manière à ce que les cookies entrants ne soient enregistrés qu’après confirmation de sa part ou soient refusés de façon globale. Pour améliorer le confort de navigation du client, SIE et TVT Services recommandent d’accepter les cookies et de ne pas les supprimer. Dans la mesure où les cookies sont bloqués, il se peut que certaines fonctions interactives de ce site ou d’un autre site Internet ne soient plus pleinement opérationnelles.",
      ]}
      title="12. Analyse du comportement de navigation"
    />
    <Section
      paragraphs={[
        "Les fonctionnalités de l’Espace Client dépendent de vos contrats d’électricité et de multimédia souscrits ainsi que de votre type de compteur. Il se peut dès lors que toutes les fonctions ne soient pas disponibles dans certains cas. SIE et TVT Services se réservent en outre le droit de modifier, d’enrichir ou de suspendre à tout moment les fonctionnalités de l’Espace Client.",
      ]}
      title="13. Fonctionnalités de l’Espace Client"
    />
    <Section
      paragraphs={[
        "SIE et TVT Services sont en droit de modifier à tout moment les présentes Conditions d’utilisation. Toute modification est communiquée sous une forme appropriée. La version la plus récente, que l’utilisateur devra accepter afin de pouvoir continuer à utiliser s’applique.",
      ]}
      title="14. Modification des conditions d’utilisation de l’Espace Client"
    />
    <Section
      paragraphs={[
        "Ces dispositions sont soumises au droit applicable. Dans le cadre de l’application du droit suisse, Crissier est le for judiciaire exclusif.",
      ]}
      title="15. Droit applicable et for"
    />
    <Section
      paragraphs={[
        "Pour l’utilisation de l’Espace Client, la Déclaration sur la protection des données et les Remarques juridiques mentionnées sur le site Internet de SIE et TVT Services s’appliquent en complément des présentes Conditions d’utilisation.",
      ]}
      title="16. Disposition complémentaire"
    />
    <Separator />
    <Text>2022 © SIE SA & TVT Services SA</Text>
  </Row>
);

export default TermsOfService;
