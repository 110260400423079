/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../../../redux/actionType";
import * as types from "./consumption.actionTypes";

const initialState = {
  isComplex: null,
  consumption: null,
  addresses: [],
  history: null,
  historyForSecondChart: null,
  isFetchingConsumptionAndAddresses: false,
  isFetchingHistoryForSecondChart: false,
  isFetchingHistory: false,
  isFetchingCsv: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_CONSUMPTION_AND_ADDRESSES_START:
      return {
        ...state,
        isFetchingConsumptionAndAddresses: true,
      };
    case types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_START:
      return {
        ...state,
        isFetchingHistoryForSecondChart: true,
      };
    case types.FETCH_CONSUMPTION_HISTORY_START:
      return {
        ...state,
        isFetchingHistory: true,
      };
    case types.FETCH_CONSUMPTION_AND_ADDRESSES_SUCCESS:
      return {
        ...state,
        isComplex: action.payload.isComplex,
        addresses: action.payload.addresses,
        consumption: action.payload.consumption,
        isFetchingConsumptionAndAddresses: false,
      };
    case types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_SUCCESS:
      return {
        ...state,
        historyForSecondChart: {
          data: action.payload.history,
          totalConsumption: action.payload.totalConsumption,
          barSize: action.payload.barSize,
          hasSolarPanelTarif: action.payload.hasSolarPanelTarif,
          hasPermanentTarif: action.payload.hasPermanentTarif,
        },
        isFetchingHistoryForSecondChart: false,
      };
    case types.FETCH_CONSUMPTION_AND_ADDRESSES_FAILURE:
      return {
        ...state,
        isFetchingConsumptionAndAddresses: false,
      };
    case types.FETCH_CONSUMPTION_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          data: action.payload.history,
          totalConsumption: action.payload.totalConsumption,
          barSize: action.payload.barSize,
          hasSolarPanelTarif: action.payload.hasSolarPanelTarif,
          hasPermanentTarif: action.payload.hasPermanentTarif,
        },
        isFetchingHistory: false,
      };
    case types.FETCH_CONSUMPTION_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingHistory: false,
      };
    case types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_FAILURE:
      return {
        ...state,
        isFetchingHistoryForSecondChart: false,
      };
    case types.DOWNLOAD_CSV_START:
      return {
        ...state,
        isFetchingCsv: true,
      };
    case types.DOWNLOAD_CSV_SUCCESS:
    case types.DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        isFetchingCsv: false,
      };
    default:
      return { ...state };
  }
};
