/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import classNames from "classnames/bind";
import React from "react";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import styles from "./Dropdown.module.scss";

const cx = classNames.bind(styles);

interface IDropdownProps {
  name: string;
  id: string;
  className?: string;
  options: string[];
  defaultValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dropdown = React.forwardRef<HTMLSelectElement, IDropdownProps>(
  (
    {
      name,
      id,
      options,
      defaultValue,
      placeholder,
      disabled = false,
      onChange = () => {},
      className,
    }: IDropdownProps,
    forwardedRef
  ) => (
    <fieldset
      className={cx(
        styles.dropdownWrapper,
        disabled && styles.dropdownWrapperDisabled,
        className
      )}
    >
      <select
        className={styles.dropdown}
        defaultValue={defaultValue || placeholder}
        id={id}
        name={name}
        ref={forwardedRef}
        onChange={(e) => {
          onChange(e);
          trackClickHandler();
        }}
      >
        {placeholder && <option disabled>{placeholder}</option>}
        {options.map((option) => (
          <option className={styles.dropdownOption} key={option}>
            {option}
          </option>
        ))}
      </select>
    </fieldset>
  )
);

export default Dropdown;
