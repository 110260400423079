/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

const footerClicksTracker = () => {
  const result = (window as any).dataLayer.find(
    (layer) => layer.data === "footer"
  );
  if (!result) {
    (window as any).dataLayer.push({
      event: "footerTracker",
      data: "footer",
      clicks: 1,
    });
  } else {
    result.clicks += 1;
  }
};

export default footerClicksTracker;
