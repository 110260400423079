/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { Router, Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PAGES } from "./common/constants/common";

// Services
import { history } from "./services";

// Routers
import PublicRouter from "./components/routers/PublicRouter";
import PrivateRouter from "./components/routers/PrivateRouter";
import ScrollOnTop from "./components/routers/ScrollOnTop";

// Components
import PageLayout from "./components/organisms/pageLayout/PageLayout";

// Pages
import SignIn from "./modules/public/signIn/SignIn";
import SignUp from "./modules/public/signUp/SignUp";
import ForgottenPassword from "./modules/public/forgottenPassword/ForgottenPassword";
import Error500 from "./modules/errors/error500/error500";
import Error404 from "./modules/errors/error404/error404";
import MyProfile from "./modules/auth/myProfile/MyProfile";
import Dashboard from "./modules/electricityAndMultimedia/dashboard/Dashboard";
import Invoices from "./modules/electricityAndMultimedia/invoices/Invoices";
import ContactAndForms from "./modules/electricityAndMultimedia/contactAndForms/ContactAndForms";
import Consumption from "./modules/electricity/consumption/Consumption";
import EnergyOffers from "./modules/electricity/energyOffers/EnergyOffers";
import MeterReadings from "./modules/electricity/meterReadings/MeterReadings";
import HelpAndFaqs from "./modules/electricityAndMultimedia/helpAndFaqs/HelpAndFaqs";
import MyMultimediaOffer from "./modules/multimedia/myMultimediaOffer/MyMultimediaOffer";
import SignUpConfirmation from "./modules/public/signUpConfirmation/SignUpConfirmation";
import ResetPassword from "./modules/public/resetPassword/ResetPassword";
import AdminLanding from "./modules/admin/adminLanding/AdminLanding";
import AdminDashboard from "./modules/admin/adminDashboard/AdminDashboard";
import config from "./common/config/global.config";
import TermsOfService from "./modules/public/termsOfService/TermsOfService";
import DataProtection from "./modules/public/dataProtection/DataProtection";
import ComingSoon from "./modules/public/comingSoon/ComingSoon";

const App = () => (
  <Router history={history}>
    <ScrollOnTop />
    <ToastContainer
      containerId="top-right"
      position={toast.POSITION.TOP_RIGHT}
    />
    <Switch>
      {/* public routes */}
      <Route
        exact
        path={PAGES.SIGN_IN}
        render={() => <PublicRouter page={<SignIn />} />}
      />
      <Route
        exact
        path={PAGES.SIGN_UP}
        render={() => <PublicRouter page={<SignUp />} />}
      />
      <Route
        exact
        path={PAGES.SIGN_UP_CONFIRMATION}
        render={() => <PublicRouter page={<SignUpConfirmation />} />}
      />
      <Route
        exact
        path={PAGES.FORGOTTEN_PASSWORD}
        render={() => <PublicRouter page={<ForgottenPassword />} />}
      />
      <Route
        exact
        path={PAGES.RESET_PASSWORD}
        render={() => <PublicRouter page={<ResetPassword />} />}
      />
      <Route
        exact
        path={PAGES.TERMS_OF_SERVICE}
        render={() => (
          <PageLayout
            hasWhiteTransparentBackground
            content={<TermsOfService />}
            type="public"
          />
        )}
      />
      <Route
        exact
        path={PAGES.DATA_PROTECTION}
        render={() => (
          <PageLayout
            hasWhiteTransparentBackground
            content={<DataProtection />}
            type="public"
          />
        )}
      />
      <Route
        exact
        path={PAGES.COMING_SOON}
        render={() => <PageLayout content={<ComingSoon />} type="public" />}
      />
      {/* electricity and multimedia routes */}
      <Route
        exact
        path={PAGES.DASHBOARD}
        render={() => <PrivateRouter page={<Dashboard />} />}
      />
      <Route
        exact
        path={PAGES.MY_INVOICES}
        render={() => <PrivateRouter page={<Invoices />} />}
      />
      <Route
        exact
        path={PAGES.CONTACT_AND_FORMS}
        render={() => <PrivateRouter page={<ContactAndForms />} />}
      />
      <Route
        exact
        path={PAGES.HELP_AND_FAQS}
        render={() => <PrivateRouter page={<HelpAndFaqs />} />}
      />
      {/* electricity routes */}
      <Route
        exact
        path={PAGES.MY_CONSUMPTION}
        render={() => <PrivateRouter page={<Consumption />} />}
      />
      <Route
        exact
        path={PAGES.MY_METER_READINGS}
        render={() => <PrivateRouter page={<MeterReadings />} />}
      />
      <Route
        exact
        path={PAGES.MY_ENERGY_OFFER}
        render={() => <PrivateRouter page={<EnergyOffers />} />}
      />
      {/* multimedia routes */}
      <Route
        exact
        path={PAGES.MY_MULTIMEDIA_OFFER}
        render={() => <PrivateRouter page={<MyMultimediaOffer />} />}
      />
      {/* other private routes */}
      <Route
        exact
        path={PAGES.MY_PROFILE}
        render={() => <PrivateRouter page={<MyProfile />} />}
      />
      {/* admin routes */}
      {config.API_USER_TYPE === "admin" && (
        <>
          <Route
            exact
            path={PAGES.ADMIN_LANDING}
            render={() => <PublicRouter page={<AdminLanding />} />}
          />
          <Route
            exact
            path={PAGES.ADMIN_DASHBOARD}
            render={() => <PublicRouter page={<AdminDashboard />} />}
          />
        </>
      )}
      {/* error routes */}
      <Route
        exact
        path="/error500"
        render={() => <PublicRouter page={<Error500 />} />}
      />
      <Route path="*" render={() => <PublicRouter page={<Error404 />} />} />
    </Switch>
  </Router>
);

export default App;
