/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { AppState } from "../../../../../redux/store";

export const postalCodesSelector = (state: AppState) =>
  state.addressForms["postalcodes"];
export const isFetchPostalCodesSelector = (state: AppState) =>
  state.addressForms["isFetchingPostalCodes"];
