/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import * as types from "./admin.actionTypes";
import { AppAction } from "../../redux/actionType";

const initialState = {
  isFetching: false,
  isSubmitting: false,
  isAdminConnectionChecked: false,
  user: null,
  isFetchingUser: false,
  isResendingVerificationEmail: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.IMPERSONATE_USER_START:
    case types.DELETE_USER_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.IMPERSONATE_USER_SUCCESS:
    case types.IMPERSONATE_USER_FAILURE:
    case types.DELETE_USER_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: null,
        isSubmitting: false,
      };
    case types.CHECK_ADMIN_CONNECTION_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.CHECK_ADMIN_CONNECTION_SUCCESS:
      return {
        ...state,
        isAdminConnectionChecked: true,
        isFetching: false,
      };
    case types.CHECK_ADMIN_CONNECTION_FAILURE:
      return {
        ...state,
        isAdminConnectionChecked: false,
        isFetching: false,
      };
    case types.RETRIEVE_USER_START:
      return {
        ...state,
        isFetchingUser: true,
      };
    case types.RETRIEVE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetchingUser: false,
      };
    case types.RETRIEVE_USER_FAILURE:
      return {
        ...state,
        user: null,
        isFetchingUser: false,
      };
    case types.RESENDING_VERIFICATION_START:
      return {
        ...state,
        isResendingVerificationEmail: true,
      };
    case types.RESENDING_VERIFICATION_END:
      return {
        ...state,
        isResendingVerificationEmail: false,
      };
    default:
      return { ...state };
  }
};
