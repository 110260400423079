/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import styles from "./Separator.module.scss";

const cx = classNames.bind(styles);

type SeparatorProps = {
  size?: "small" | "medium" | "large" | "xlarge";
  className?: string;
};

const Separator = ({ size = "medium", className = "" }: SeparatorProps) => (
  <div className={cx(styles[size], className)} />
);

export default Separator;
