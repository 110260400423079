/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppState } from "../../../../redux/store";

export const myMultimediaOfferSelector = (state: AppState) =>
  state.multimediaOffer["multimediaOffer"];
export const externalClientSelector = (state: AppState) =>
  state.multimediaOffer["externalClient"];
export const otoSelector = (state: AppState) => state.multimediaOffer["oto"];
export const isFetchingMyMultimediaOfferSelector = (state: AppState) =>
  state.multimediaOffer["isFetching"];
export const isSubmittingMyMultimediaOfferSelector = (state: AppState) =>
  state.multimediaOffer["isSubmitting"];
