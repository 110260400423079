/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppState } from "../../../../redux/store";

export const invoicesAddressesSelector = (state: AppState) =>
  state.invoices["addresses"];
export const invoicesIsComplexSelector = (state: AppState) =>
  state.invoices["isComplex"];
export const invoicesSelector = (state: AppState) => state.invoices["invoices"];
export const latestInvoiceSelector = (state: AppState) =>
  state.invoices["latestInvoice"];
export const invoiceReceptionSelector = (state: AppState) =>
  state.invoices["invoiceReception"];
export const currentPageInvoicesSelector = (state: AppState) =>
  state.invoices["currentPageInvoices"];
export const totalResultsInvoicesSelector = (state: AppState) =>
  state.invoices["totalResultsInvoices"];
export const isFetchingInvoicesAddressesSelector = (state: AppState) =>
  state.invoices["isFetchingAddresses"];
export const isFetchingInvoicesHistorySelector = (state: AppState) =>
  state.invoices["isFetchingInvoices"];
export const isFetchingLatestInvoiceSelector = (state: AppState) =>
  state.invoices["isFetchingLatestInvoice"];
export const isFetchingInvoiceReceptionSelector = (state: AppState) =>
  state.invoices["isFetchingInvoiceReception"];
export const isSubmittingInvoicesSelector = (state: AppState) =>
  state.invoices["isSubmitting"];
