/* eslint-disable linebreak-style */
/* eslint-disable quotes */
const config = {
  API_URL: process.env.REACT_APP_API_URL || "http://localhost:5000",
  API_HEADER_OCP_APIM_SUBSCRIPTION_KEY:
    process.env.REACT_APP_API_HEADER_OCP_APIM_SUBSCRIPTION_KEY ||
    "a0fe7f215acd4e66b7bcf2e68e80166e",
  API_HEADER_OCP_APIM_TRACE:
    process.env.REACT_APP_API_HEADER_OCP_APIM_TRACE ?? true,
  API_USER_TYPE: process.env.REACT_APP_API_USER_TYPE || "public",
};

export default config;
