/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios, { AxiosResponse } from "axios";

import {
  fetchInvoicesAddressesApi,
  fetchInvoicesReceptionModeApi,
  fetchMyProfileAddressApi,
  fetchMyProfileContactApi,
  fetchMyProfileElectricityContractApi,
  fetchMyProfileMultimediaApi,
  fetchMyProfileMultimediaContractApi,
  fetchUserApi,
  updateAddressApi,
  updateEmailApi,
  updateMobileApi,
  updatePasswordApi,
  updateTelephoneApi,
} from "../../api/auth.api";
import {
  PAGES,
  PRIMARY_COLORS,
  ROLES,
  SESSION_STORAGE,
} from "../../common/constants/common";
import { changeAppPrimaryColor } from "../../common/helpers/helpers";
import { AppDispatch, AppState } from "../../redux/store";
import { history, toastError, toastSuccess } from "../../services";
import * as types from "./auth.actionTypes";
import { userSelector } from "./auth.selectors";
import { fetchUserResponseData } from "./auth.types";

export const signOut = () => (dispatch: AppDispatch) => {
  const userIsAdmin =
    sessionStorage.getItem(SESSION_STORAGE.ROLE) === ROLES.ADMIN;
  dispatch({ type: types.SIGN_OUT });
  history.push(userIsAdmin ? PAGES.ADMIN_DASHBOARD : PAGES.SIGN_IN);
  sessionStorage.clear();
  changeAppPrimaryColor({ color: PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA });
};

export const fetchUser = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: types.FETCH_USER_START });
    const response: AxiosResponse<fetchUserResponseData> = await fetchUserApi(
      userId
    );
    dispatch({ type: types.FETCH_USER_SUCCESS, payload: response.data.data });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        dispatch(signOut());
        toastError({ message: "Non autorisé" });
      }
    }
    dispatch({ type: types.FETCH_USER_FAILURE });
  }
};

export const fetchMyProfileData =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch({ type: types.FETCH_MY_PROFILE_DATA_START });
      const user = userSelector(getState());

      const response = await Promise.all([
        fetchMyProfileAddressApi(user.id),
        fetchMyProfileContactApi(user.id),
        fetchMyProfileMultimediaApi(user.id),
      ]);

      const address = response[0].data.data;
      const contact = response[1].data.data;
      const multimedia = {};

      dispatch({
        type: types.FETCH_MY_PROFILE_DATA_SUCCESS,
        payload: { address, contact, multimedia },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_MY_PROFILE_DATA_FAILURE });
    }
  };

export const updateMyProfileData =
  (
    // eslint-disable-next-line
    data: object
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: types.UPDATE_MY_PROFILE_DATA_START });
      setTimeout(() => {
        dispatch({ type: types.UPDATE_MY_PROFILE_DATA_SUCCESS });
        toastSuccess({
          message: "Modifié avec succès",
        });
      }, 1000);
    } catch (error) {
      dispatch({ type: types.UPDATE_MY_PROFILE_DATA_FAILURE });
    }
  };

export const fetchElectricityOffer =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch({ type: types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_START });
      const user = userSelector(getState());

      const response = await fetchMyProfileElectricityContractApi(user?.id);

      dispatch({
        type: types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_MY_PROFILE_ELECTRICITY_OFFER_FAILURE });
    }
  };

export const updateAddress =
  ({ data, callback }) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const user = userSelector(getState());

      await updateAddressApi(user.id, data);

      toastSuccess({
        message:
          "Demande envoyée ou en cours de traitement. La modification sera validée sous 3 jours.",
        id: types.UPDATE_MY_PROFILE_DATA_SUCCESS,
      });
      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.UPDATE_MY_PROFILE_DATA_FAILURE });
    }
  };

export const updateMobilePhone =
  ({ data, callback }) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const user = userSelector(getState());

      await updateMobileApi(user.id, data);

      toastSuccess({
        message:
          "Demande envoyée ou en cours de traitement. La modification sera validée sous 3 jours.",
        id: types.UPDATE_MY_PROFILE_DATA_SUCCESS,
      });
      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.UPDATE_MY_PROFILE_DATA_FAILURE });
    }
  };

export const updateHomePhone =
  ({ data, callback }) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const user = userSelector(getState());

      await updateTelephoneApi(user.id, data);

      toastSuccess({
        message:
          "Demande envoyée ou en cours de traitement. La modification sera validée sous 3 jours.",
        id: types.UPDATE_MY_PROFILE_DATA_SUCCESS,
      });
      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.UPDATE_MY_PROFILE_DATA_FAILURE });
    }
  };

export const updateEmail =
  ({ data, callback, setError }) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const user = userSelector(getState());
      dispatch({ type: types.UPDATE_EMAIL_START });

      await updateEmailApi(user.id, { newEmail: data.newEmail });

      dispatch({ type: types.UPDATE_EMAIL_SUCCESS, payload: data.email });
      toastSuccess({
        message:
          "La mise à jour de votre adresse e-mail sera traitée dans les plus brefs délais",
        id: types.UPDATE_EMAIL_SUCCESS,
      });
      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 417) {
          setError("newEmail", {
            type: "emailTaken",
            message: "Email déjà utilisé",
          });
        }
      }
      dispatch({ type: types.UPDATE_EMAIL_FAILURE });
    }
  };

export const updatePassword =
  ({ data, callback, setError }) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch({ type: types.UPDATE_PASSWORD_START });
      const user = userSelector(getState());

      await updatePasswordApi(user.id, data);

      toastSuccess({
        message: "Mettre à jour le mot de passe avec succès",
        id: types.UPDATE_PASSWORD_SUCCESS,
      });
      dispatch({ type: types.UPDATE_PASSWORD_SUCCESS });

      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 417) {
          setError("oldPassword", {
            type: "incorrectPassword",
            message: "Ancien mot de passe incorrect",
          });
        }
      }
      dispatch({ type: types.UPDATE_PASSWORD_FAILURE });
    }
  };

export const fetchInvoicesAddresses =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch({ type: types.FETCH_INVOICES_ADDRESSES_START });
      const user = userSelector(getState());

      const response = await fetchInvoicesAddressesApi(user.id);

      dispatch({
        type: types.FETCH_INVOICES_ADDRESSES_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_INVOICES_ADDRESSES_FAILURE });
    }
  };

export const fetchInvoiceReceptionMode =
  (objectId) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      dispatch({ type: types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_START });
      const user = userSelector(getState());

      const response = await fetchInvoicesReceptionModeApi(user.id, objectId);

      dispatch({
        type: types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_INVOICE_ADDRESS_RECEPTION_MODE_FAILURE });
    }
  };
