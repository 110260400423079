/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { combineReducers } from "@reduxjs/toolkit";
import adminReducer from "../modules/admin/admin.reducer";
import { SIGN_OUT } from "../modules/auth/auth.actionTypes";
import authReducer from "../modules/auth/auth.reducer";
import addressFormsReducer from "../modules/auth/myProfile/forms/service/addressForms.reducer";
import consumptionReducer from "../modules/electricity/consumption/service/consumption.reducer";
import meterReadingsReducer from "../modules/electricity/meterReadings/service/meterReadings.reducer";
import contactAndFormsReducer from "../modules/electricityAndMultimedia/contactAndForms/service/contactAndForms.reducer";
import dashboardReducer from "../modules/electricityAndMultimedia/dashboard/service/dashboard.reducer";
import myInvoicesReducer from "../modules/electricityAndMultimedia/invoices/service/invoices.reducer";
import myMultimediaOfferReducer from "../modules/multimedia/myMultimediaOffer/service/myMultimediaOffer.reducer";
import publicReducer from "../modules/public/public.reducer";

const appReducer = combineReducers({
  auth: authReducer,
  public: publicReducer,
  admin: adminReducer,
  invoices: myInvoicesReducer,
  dashboard: dashboardReducer,
  contactAndForms: contactAndFormsReducer,
  meterReadings: meterReadingsReducer,
  multimediaOffer: myMultimediaOfferReducer,
  consumption: consumptionReducer,
  addressForms: addressFormsReducer,
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGN_OUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;
