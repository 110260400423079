/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import { MouseEventHandler, ReactNode, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./Underlay.module.scss";

const cx = classNames.bind(styles);

type UnderlayProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  opacity?: 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;
  className?: string;
};

const Underlay = ({ onClick, opacity = 0, className = "" }: UnderlayProps) => (
  <div
    aria-label="underlay"
    className={cx(
      styles.underlay,
      { [styles.blackBackground]: opacity },
      className
    )}
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={() => {}}
    {...(opacity && { style: { opacity } })}
  />
);

export default Underlay;
