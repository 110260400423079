/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../redux/actionType";
import * as types from "./public.actionTypes";

const initialState = {
  isFetching: false,
  isSubmitting: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.SIGN_UP_START:
    case types.SIGN_IN_START:
    case types.FORGOTTEN_PASSWORD_START:
    case types.RESET_PASSWORD_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.SIGN_UP_SUCCESS:
    case types.SIGN_IN_SUCCESS:
    case types.FORGOTTEN_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.SIGN_UP_FAILURE:
    case types.SIGN_IN_FAILURE:
    case types.FORGOTTEN_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return { ...state };
  }
};
