/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../../../redux/actionType";
import * as types from "./meterReadings.actionTypes";

const initialState = {
  isComplex: null,
  meterReadings: [],
  counters: [],
  meterReadingsAddresses: [],
  meterReadingsHistory: [],
  totalResultsMeterReadingsHistory: 0,
  isFetching: false,
  isFetchingCounters: false,
  isFetchingHistory: false,
  isSubmitting: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_METER_READINGS_DATA_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_COUNTERS_START:
      return {
        ...state,
        isFetchingCounters: true,
      };
    case types.FETCH_METER_READINGS_HISTORY_START:
      return {
        ...state,
        isFetchingHistory: true,
      };
    case types.FETCH_METER_READINGS_DATA_SUCCESS:
      return {
        ...state,
        meterReadings: action.payload.meterReadings,
        meterReadingsAddresses: action.payload.meterReadingsAddresses,
        isComplex: action.payload.isComplex,
        isFetching: false,
      };
    case types.FETCH_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload,
        isFetchingCounters: false,
      };
    case types.FETCH_METER_READINGS_HISTORY_SUCCESS:
      return {
        ...state,
        meterReadingsHistory: action.payload.meterReadingsHistory,
        totalResultsMeterReadingsHistory:
          action.payload.totalResultsMeterReadingsHistory,
        isFetchingHistory: false,
      };
    case types.FETCH_METER_READINGS_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCH_COUNTERS_FAILURE:
      return {
        ...state,
        isFetchingCounters: false,
      };
    case types.CLEAR_COUNTERS:
      return {
        ...state,
        counters: [],
      };
    case types.FETCH_METER_READINGS_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingHistory: false,
      };
    case types.POST_COUNTER_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.POST_COUNTER_SUCCESS:
      return {
        ...state,
        // TODO: edit counter
        isSubmitting: false,
      };
    case types.POST_COUNTER_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.ALL_ADDRESSES_UNCHECKED:
      return {
        ...state,
        meterReadingsHistory: [],
        currrentPageMeterReadingsHistory: 1,
        totalResultsMeterReadingsHistory: 0,
      };
    default:
      return { ...state };
  }
};
