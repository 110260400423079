/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
import classNames from "classnames/bind";
import { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Legend, Pie, PieChart, Tooltip } from "recharts";
import { PAGES } from "../../../common/constants/common";
import { formatDate } from "../../../common/helpers/dates";
import { roundNumber } from "../../../common/helpers/helpers";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import { Col, Link, Radio, Row, Separator, Text, Title } from "../index";
import styles from "./ConsumptionGraph.module.scss";

type TooltipCoords = {
  x: number;
  y: number;
};

type ConsumptionGraphProps = {
  data?: any;
};

const cx = classNames.bind(styles);

const renderLegend = ({ props, horizontal = false }) => {
  const { payload } = props;

  return (
    <div
      className={cx(styles.legendWrapper, {
        [styles.legendWrapperKwh]: horizontal,
      })}
    >
      {payload.map((entry) => {
        if (!entry.payload.value) {
          return null;
        }
        return (
          <span
            className={cx(
              styles.legendItem,
              styles[`legendItem${entry.color.substring(1).toLowerCase()}`]
            )}
            key={`item-${entry.value}`}
          >
            {entry.value}
          </span>
        );
      })}
    </div>
  );
};

const renderTooltip = (props, measure: string) => {
  if ((!props.payload && !props.payload.length) || window.innerWidth < 1200) {
    return null;
  }
  const tooltips = props.payload;
  return (
    <>
      {tooltips.map((tooltip) => (
        <div
          className={styles.tooltip}
          key={`${tooltip.name}_${tooltip.value}`}
        >
          <span
            className={cx(
              styles.tooltipLabel,
              styles[
                `tooltipLabel${tooltip.payload.fill.substring(1).toLowerCase()}`
              ]
            )}
          >
            {tooltip.name}
          </span>
          <span
            className={cx(
              styles.tooltipLabelValue,
              styles[
                `tooltipLabelValue${tooltip.payload.fill
                  .substring(1)
                  .toLowerCase()}`
              ]
            )}
          >
            {tooltip.value} {measure}
          </span>
        </div>
      ))}
    </>
  );
};

const ConsumptionGraph = ({ data }: ConsumptionGraphProps) => {
  const location = useLocation();

  const { currentConsumptionKwh, currentConsumptioninCurrency } = data || {};

  const [tooltipPosition, setTooltipPosition] = useState<TooltipCoords>({
    x: 0,
    y: 0,
  });

  const CHART_DATA_KWH =
    currentConsumptionKwh?.pastMonthConsumptionPermanentkWh
      ? [
          {
            name: "Consommation - Permanent",
            value: currentConsumptionKwh?.pastMonthConsumptionPermanentkWh,
            fill: "#8a1e12",
          },
          {
            name: "Injection - Heures Creuses",
            value: currentConsumptionKwh?.pastMonthSolarPanelsDiscountPricekWh,
            fill: "#89D580",
          },
          {
            name: "Injection - Heures Pleines",
            value: currentConsumptionKwh?.pastMonthSolarPanelsFullPricekWh,
            fill: "#337D2C",
          },
        ]
      : [
          {
            name: "Consommation - Heures Creuses",
            value: currentConsumptionKwh?.pastMonthConsumptionDiscountPricekWh,
            fill: "#d58080",
          },
          {
            name: "Consommation - Heures Pleines",
            value: currentConsumptionKwh?.pastMonthConsumptionFullPricekWh,
            fill: "#e6321e",
          },
          {
            name: "Injection - Heures Creuses",
            value: currentConsumptionKwh?.pastMonthSolarPanelsDiscountPricekWh,
            fill: "#89D580",
          },
          {
            name: "Injection - Heures Pleines",
            value: currentConsumptionKwh?.pastMonthSolarPanelsFullPricekWh,
            fill: "#337D2C",
          },
          {
            name: "Autoconsommation production solaire - Heures Creuses",
            value: currentConsumptionKwh?.pastSelfConsumptionDiscountPricekWh,
            fill: "#C6D084",
          },
          {
            name: "Autoconsommation production solaire - Heures Pleines",
            value: currentConsumptionKwh?.pastSelfConsumptionFullPricekWh,
            fill: "#98A444",
          },
          {
            name: "Totalité autoconsommation des participants du RCP - Heures Creuses",
            value: currentConsumptionKwh?.pastTotalSelfConsumptionDiscountPricekWh,
            fill: "#80CBD5",
          },
          {
            name: "Totalité autoconsommation des participants du RCP - Heures Pleines",
            value: currentConsumptionKwh?.pastTotalSelfConsumptionFullPricekWh,
            fill: "#2E747D",
          },
        ];

  const CHART_DATA_CHF = [
    {
      name: "Électricité",
      value: currentConsumptioninCurrency?.electricite,
      fill: "#0073A3",
    },
    {
      name: "Utilisation du réseau régional",
      value: currentConsumptioninCurrency?.utilisationRegional,
      fill: "#F5CA2F",
    },
    {
      name: "Utilisation du réseau national ",
      value: currentConsumptioninCurrency?.utilisationNational,
      fill: "#FF6918",
    },
    {
      name: "Taxes publiques et émoluments",
      value: currentConsumptioninCurrency?.publicTaxes,
      fill: "#934FC9",
    },
    {
      name: "Multimédia",
      value: currentConsumptioninCurrency?.multimedia,
      fill: "#31B07B",
    },
    {
      name: "TVA et divers",
      value: currentConsumptioninCurrency?.tvaTaxes,
      fill: "#F95A9D",
    },
  ];

  const tooltipPositionHandler = (tooltipData, i, e) => {
    setTooltipPosition({
      x: e.nativeEvent.layerX >= 365 ? 440 : 150,
      y: e.nativeEvent.layerY < 100 ? 10 : 120,
    });
  };

  const calculateConsumptionInKwh = useMemo(() => roundNumber(
    currentConsumptionKwh?.pastMonthConsumptionPermanentTarif
      ? currentConsumptionKwh.pastMonthConsumptionPermanentTarif
      : CHART_DATA_KWH.reduce((acc, curr) => acc + curr.value, 0)
  ), [currentConsumptionKwh]);

  const calculateConsumptionInCHF = useMemo(() => roundNumber(
    CHART_DATA_CHF.reduce((acc, curr) => acc + curr.value, 0)
  ), [currentConsumptionKwh]);

  if (!calculateConsumptionInKwh && !calculateConsumptionInCHF) {
    return (
      <Col isContentBox className={styles.consumptionGraphNoData} span={12}>
        <Title isBoxTitle level={3}>
          Pas de rapport de consommation périodique disponible
        </Title>
      </Col>
    );
  }

  const [currentDisplayOption, setCurrentDisplayOption] = useState(
    calculateConsumptionInKwh ? "KWH" : "CHF"
  );

  return (
    <Col isContentBox className={styles.consumptionGraph} span={12}>
      <Title isBoxTitle className={styles.red} level={3}>
        Énergie totale dernière période
      </Title>
      <Text className={styles.lastConsumptionPeriod}>
        {currentDisplayOption === "KWH"
          ? currentConsumptionKwh?.consumptionDate
          : `Du ${formatDate(
              new Date(currentConsumptioninCurrency?.startDate),
              "dd.mm.yyyy"
            )} au ${formatDate(
              new Date(currentConsumptioninCurrency?.endDate),
              "dd.mm.yyyy"
            )}`}
      </Text>
      {currentDisplayOption === "KWH" ? (
        <PieChart height={340} width={730}>
          <text
            dominantBaseline="middle"
            fill="#6a6a6a"
            fontSize={14}
            textAnchor="middle"
            x={365}
            y={70}
          >
            KWH
          </text>
          <text
            dominantBaseline="middle"
            fill="#272626"
            fontSize={
              calculateConsumptionInKwh.toString().length >= 7 ? 22 : 35
            }
            fontWeight="bold"
            textAnchor="middle"
            x={365}
            y={100}
          >
            {calculateConsumptionInKwh}
          </text>
          <Pie
            cy={85}
            data={CHART_DATA_KWH}
            dataKey="value"
            innerRadius={70}
            outerRadius={80}
            paddingAngle={0}
            onMouseEnter={tooltipPositionHandler}
          />
          <Tooltip
            content={(props) =>
              renderTooltip(props, currentDisplayOption.toLowerCase())
            }
            isAnimationActive={false}
            position={{ ...tooltipPosition }}
            wrapperStyle={{ maxWidth: "220px" }}
          />
          <Legend
            content={(props) => renderLegend({ props, horizontal: true })}
            layout="vertical"
            verticalAlign="bottom"
          />
        </PieChart>
      ) : (
        <PieChart height={340} width={730}>
          <text
            dominantBaseline="middle"
            fill="#6a6a6a"
            fontSize={14}
            textAnchor="middle"
            x={365}
            y={70}
          >
            CHF
          </text>
          <text
            dominantBaseline="middle"
            fill="#272626"
            fontSize={
              calculateConsumptionInCHF.toString().length >= 7 ? 22 : 35
            }
            fontWeight="bold"
            textAnchor="middle"
            x={365}
            y={100}
          >
            {calculateConsumptionInCHF}
          </text>
          <Pie
            cy={85}
            data={CHART_DATA_CHF}
            dataKey="value"
            innerRadius={70}
            outerRadius={80}
            paddingAngle={0}
            onMouseEnter={tooltipPositionHandler}
          />
          <Tooltip
            content={(props) => renderTooltip(props, currentDisplayOption)}
            isAnimationActive={false}
            position={{ ...tooltipPosition }}
            wrapperStyle={{ maxWidth: "220px" }}
          />
          <Legend
            content={(props) => renderLegend({ props })}
            layout="vertical"
            verticalAlign="bottom"
          />
        </PieChart>
      )}
      {currentDisplayOption === "KWH" && (
        <>
          <Separator size="small" />
          <Text className={styles.offPeakHoursText}>
            *22h à 6h et week-ends
          </Text>
          <Separator size="small" />
          {location.pathname !== "/my-consumption" && (
            <Link
              isLight
              className={styles.red}
              to={PAGES.MY_CONSUMPTION}
              onClick={trackClickHandler}
            >
              Voir ma consommation
            </Link>
          )}
        </>
      )}
      {Boolean(calculateConsumptionInKwh && calculateConsumptionInCHF) && (
        <>
          <Separator />
          <Row
            className={styles.chartDisplayOptionsRow}
            justify="space-between"
          >
            <Text isLight>Affichage :</Text>
            <Radio
              defaultChecked
              className={styles.chartKwhOption}
              id="KWH"
              name="chartDisplayOption"
              onChange={() => {
                trackClickHandler();
                setCurrentDisplayOption("KWH");
              }}
            >
              kWh
            </Radio>
            <Radio
              id="CHF"
              name="chartDisplayOption"
              onChange={() => {
                trackClickHandler();
                setCurrentDisplayOption("CHF");
              }}
            >
              CHF
            </Radio>
          </Row>
        </>
      )}
    </Col>
  );
};

export default ConsumptionGraph;
