/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppState } from "../../../../redux/store";

export const meterReadingsSelector = (state: AppState) =>
  state.meterReadings["meterReadings"];
export const countersSelector = (state: AppState) =>
  state.meterReadings["counters"];
export const meterReadingsAddressesSelector = (state: AppState) =>
  state.meterReadings["meterReadingsAddresses"];
export const meterReadingsHistorySelector = (state: AppState) =>
  state.meterReadings["meterReadingsHistory"];
export const isComplexMeterReadingsSelector = (state: AppState) =>
  state.meterReadings["isComplex"];
export const totalResultsMeterReadingsHistorySelector = (state: AppState) =>
  state.meterReadings["totalResultsMeterReadingsHistory"];
export const isFetchingMeterReadingsSelector = (state: AppState) =>
  state.meterReadings["isFetching"];
export const isFetchingCountersSelector = (state: AppState) =>
  state.meterReadings["isFetchingCounters"];
export const isFetchingMeterReadingsHistorySelector = (state: AppState) =>
  state.meterReadings["isFetchingHistory"];
export const isSubmittingMeterReadingsSelector = (state: AppState) =>
  state.meterReadings["isSubmitting"];
