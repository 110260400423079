/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import e from "express";
import SIEStore from "../../../assets/images/SIE-store.png";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Separator,
  Text,
  Textarea,
  Title,
  Loader,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { userSelector, myProfileSelector } from "../../auth/auth.selectors";

import { isSubmittingPublicSelector } from "../../public/public.selectors";
import styles from "./ContactAndForms.module.scss";
import { submitForm } from "./service/contactAndForms.actions";
import { IContactData } from "./service/contactAndForms.types";
import trackHistory from "../../../common/helpers/trackHistory";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import { fetchMyProfileData } from "../../auth/auth.actions";
import { toastError } from "../../../services";

const ContactAndForms = () => {
  const location = window.location.href;
  const dispatch = useAppDispatch();
  const { name, lastName } = useAppSelector(userSelector);
  const myProfile = useAppSelector(myProfileSelector);
  const isSubmitting = useAppSelector(isSubmittingPublicSelector);

  useEffect(() => {
    dispatch(fetchMyProfileData());
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reason: "Veuillez sélectionner la raison de contact",
      message: "",
      email: "",
    },
    shouldUnregister: true,
  });

  const onContactFormSubmit = (
    data: Omit<IContactData, "lastname" | "firstname">
  ) => {
    const apiData = data;
    if (apiData.reason === "Veuillez sélectionner la raison de contact") {
      apiData.reason = "Autres";
    }
    dispatch(
      submitForm({ ...apiData, email: myProfile?.contact?.email ?? "", firstname: name, lastname: lastName }, reset)
    );
  };

  const onContactFormError = () => {
    toastError({
      message:
        "Le formulaire est vide. Veuillez remplir tous les champs requis avant de soumettre le formulaire.",
    });
  };

  useEffect(
    () => () => {
      trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
      sessionStorage.removeItem("clicks");
    },
    []
  );

  if (!myProfile) {
    return <Loader type="page" />;
  }

  return (
    <Row align="start" direction="column" justify="start">
      <section className={styles.contactWrapper}>
        <Title>Contactez-nous</Title>
        <Row
          wrapForMobile
          align="start"
          className={styles.contactHeadSectionContent}
        >
          <Col lg={4} md={6} span={12}>
            <article className={styles.contactAddress}>
              <Title isBoxTitle level={3}>
                Votre Espace Conseils
              </Title>
              <div className={styles.contactImageWrapper}>
                <img alt="img" className={styles.contactStore} src={SIEStore} />
              </div>
              <Text align="center">
                Rue Neuve 5<br />
                1020 Renens
              </Text>
              <div className={styles.contactSeparator} />
              <Text align="center">
                Horaires d’ouverture :<br />
                Du lundi au vendredi : 9h – 18h
                <br />
                Samedi : 9h - 13h30
                <br />
              </Text>
            </article>
          </Col>
          <Col lg={8} md={6} span={12}>
            <article className={styles.contactWorkHours}>
              <Title level={3}>Nos coordonnées</Title>
              <Text>
                Email : info@sie.ch
                <br />
                Téléphone : 021 631 50 00
                <br />
                Du lundi au vendredi : 8h – 12h / 13h – 17h
                <br />
                Courrier : SIE SA Espace Conseils, rue Neuve 5, case postale,
                1020 Renens
              </Text>
              <div className={styles.contactSeparator} />
              <Title level={3}>En dehors des heures ouvrables</Title>
              <Text>
                La surveillance du réseau électrique de SIE SA est assurée par
                le centre de conduite des réseaux de Romande Energie.
                <br />
                En dehors des heures ouvrables, en cas de panne de réseau et
                pour toutes urgences énergie uniquement, les équipes
                d’intervention sont atteignables, 24h sur 24, au 0848 802 555.
                <br />
              </Text>
              <Title level={3}>
                Vous êtes un grand consommateur (100&apos;000 kWh/an)
              </Title>
              <Text>Email : ventes@sie.ch</Text>
              <Text>Téléphone : 021 631 50 01</Text>
            </article>
          </Col>
        </Row>
        <Separator size="large" />
      </section>
      <section className={styles.contactFormWrapper}>
        <Title level={2}>Formulaire de contact</Title>
        <Text>
          Pour toutes questions ou demandes, vous pouvez aussi nous envoyer un
          message via le formulaire ci-dessous, nous vous répondrons dans les
          plus brefs délais.
        </Text>
        <Text>
          Merci de sélectionner l’objet de votre demande dans « Raison du
          contact ».
        </Text>
        <Separator size="xlarge" />
        <form onSubmit={handleSubmit(onContactFormSubmit, onContactFormError)}>
          <Dropdown
            {...register("reason", {
              required: true,
            })}
            id="reason"
            name="reason"
            options={[
              "Déménagement",
              "Paiements et facturation",
              "Tarifs",
              "Relevés de compteurs",
              "Pannes et dépannages",
              "Demande d’informations",
              "Multimédia",
              "Demande d’activation de raccordement multimédia",
              "Demande de désactivation du raccordement multimédia",
              "Autres",
            ]}
            placeholder="Veuillez sélectionner la raison de contact"
          />
          <Separator size="xlarge" />
          <Textarea
            {...register("message", {
              required: true,
              maxLength: 10000,
            })}
            error={!!errors.message}
            label="Votre message"
            name="message"
            rows={15}
            onChange={trackClickHandler}
          />
          <Separator size="xlarge" />
          <div className={styles.contactButtonsWrapper}>
            <Button
              buttonStyle="secondaryButton"
              type="button"
              onClick={() => {
                reset();
                trackClickHandler();
              }}
            >
              Annuler
            </Button>
            <Button
              loading={isSubmitting}
              type="submit"
              onClick={trackClickHandler}
            >
              Valider
            </Button>
          </div>
        </form>
      </section>
    </Row>
  );
};

export default ContactAndForms;
