/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const FETCH_INVOICES_ADDRESSES_START =
  "INVOICES::FETCH_INVOICES_ADDRESSES_START";
export const FETCH_INVOICES_ADDRESSES_SUCCESS =
  "INVOICES::FETCH_INVOICES_ADDRESSES_SUCCESS";
export const FETCH_INVOICES_ADDRESSES_FAILURE =
  "INVOICES::FETCH_INVOICES_ADDRESSES_FAILURE";

export const FETCH_LATEST_INVOICE_START =
  "INVOICES::FETCH_LATEST_INVOICE_START";
export const FETCH_LATEST_INVOICE_SUCCESS =
  "INVOICES::FETCH_LATEST_INVOICE_SUCCESS";
export const FETCH_LATEST_INVOICE_FAILURE =
  "INVOICES::FETCH_LATEST_INVOICE_FAILURE";

export const FETCH_INVOICE_RECEPTION_START =
  "INVOICES::FETCH_INVOICE_RECEPTION_START";
export const FETCH_INVOICE_RECEPTION_SUCCESS =
  "INVOICES::FETCH_INVOICE_RECEPTION_SUCCESS";
export const FETCH_INVOICE_RECEPTION_FAILURE =
  "INVOICES::FETCH_INVOICE_RECEPTION_FAILURE";

export const FETCH_INVOICES_HISTORY_START =
  "INVOICES::FETCH_INVOICES_HISTORY_START";
export const FETCH_INVOICES_HISTORY_SUCCESS =
  "INVOICES::FETCH_INVOICES_HISTORY_SUCCESS";
export const FETCH_INVOICES_HISTORY_FAILURE =
  "INVOICES::FETCH_INVOICES_HISTORY_FAILURE";

export const DOWNLOAD_INVOICES_PDF_START =
  "INVOICES::DOWNLOAD_INVOICES_PDF_START";
export const DOWNLOAD_INVOICES_PDF_SUCCESS =
  "INVOICES::DOWNLOAD_INVOICES_PDF_SUCCESS";
export const DOWNLOAD_INVOICES_PDF_FAIURE =
  "INVOICES::DOWNLOAD_INVOICES_PDF_FAIURE";

export const ALL_ADDRESSES_UNCHECKED = "INVOICES::ALL_ADDRESSES_UNCHECKED";
