/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import classNames from "classnames/bind";
import { StarRoundedIcon } from "../../../assets/icons";
import { Col, Row, Separator, Text, Title } from "../../../components/ui";
import styles from "./SignUpConfirmation.module.scss";

const cx = classNames.bind(styles);

const SignUpConfirmation = () => (
  <Row direction="column">
    <Col className="position-relative" lg={3} md={5} sm={8} span={10}>
      <Title align="center" level={1}>
        Votre compte est en cours de création
      </Title>

      {/* shapes */}
      <div className={cx(styles.smallCircle, styles.smallCircleTopLeft)} />
      <div className={cx(styles.line, styles.lineTopLeft)} />
      <div className={cx(styles.bigCircle, styles.bigCircleTopLeft)} />
      <div className={cx(styles.smallCircle, styles.smallCircleTopLeft2)} />
      <div className={cx(styles.line, styles.lineTopRight)} />
      <div className={cx(styles.smallCircle, styles.smallCircleTopRight)} />
      <StarRoundedIcon className={cx(styles.star, styles.starTopRight)} />
      <div className={cx(styles.smallCircle, styles.smallCircleTopRight2)} />
    </Col>
    <Col className="position-relative" lg={3} md={5} sm={8} span={10}>
      <Text align="center" size="large">
        Vérifiez votre boite de réception email, vous allez recevoir un message
        de &nbsp;confirmation dans quelques instants. Ce message vous permettra
        de &nbsp;valider la création de votre compte.
      </Text>
      <Separator size="large" />
      <Text align="center" size="large">
        Si vous ne recevez pas votre email de validation, merci de vérifier
        votre dossier de spam.
      </Text>

      {/* shapes */}
      <StarRoundedIcon className={cx(styles.star, styles.starBottomLeft)} />
      <div className={cx(styles.line, styles.lineBottomLeft)} />
      <div className={cx(styles.line, styles.lineBottomRight)} />
      <div className={cx(styles.bigCircle, styles.bigCircleBottomRight)} />
    </Col>
  </Row>
);

export default SignUpConfirmation;
