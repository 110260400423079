/* eslint-disable linebreak-style */
/* eslint-disable no-console */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { PAGINATION } from "../../../../common/constants/pagination";
import { SORTING } from "../../../../common/constants/sorting";

// when addresses are fetched for the first time, only limited number of addresses are shown based on number
// that represents the sum of addresses counters, if show all button is pressed, all addresses are shown
export const limitAddresses = (addresses) => {
  if (!addresses.length) {
    return [];
  }

  const limitedAddresses = [addresses[0]];

  let numberOfCounters = addresses[0].listOfCounters
    ? addresses[0].listOfCounters.map((counter) => counter.counterId).length
    : 0;

  if (addresses.length < 99) {
    addresses.slice(1).forEach((address) => {
      const numberOfCountersForAddress = address.listOfCounters
        ? address.listOfCounters.map((counter) => counter.counterId).length
        : 0;
      if (
        numberOfCountersForAddress + numberOfCounters <=
        PAGINATION.ADDRESS_COUNTERS_PER_PAGE
      ) {
        limitedAddresses.push(address);
        numberOfCounters += numberOfCountersForAddress;
      }
    });
  } else {
    const flatedArray = addresses.flat();
    const objsArray = flatedArray.slice(0, 993);

    objsArray.slice(1).forEach((address) => {
      const numberOfCountersForAddress = address.listOfCounters
        ? address.listOfCounters.map((counter) => counter.counterId).length
        : 0;
      if (
        numberOfCountersForAddress + numberOfCounters <=
        PAGINATION.ADDRESS_COUNTERS_PER_PAGE
      ) {
        limitedAddresses.push(address);
        numberOfCounters += numberOfCountersForAddress;
      }
    });
  }
  return limitedAddresses.slice(0, 5);
};

// TODO: add type
export const searchAddresses = (addresses, searchValue) =>
  addresses.filter(
    (addressItem) =>
      addressItem.address
        .toLowerCase()
        .includes(searchValue.trim().toLowerCase()) ||
      addressItem.listOfCounters.some((counter) =>
        counter.toLowerCase().includes(searchValue.trim().toLowerCase())
      )
  );

// TODO: add type
export const getCurrentSort = (name, currentSort) => {
  if (
    name === "date" &&
    (currentSort === SORTING.DATE_ASC || currentSort === SORTING.DATE_DESC)
  ) {
    return currentSort === SORTING.DATE_ASC ? "ASC" : "DESC";
  }
  if (
    name === "amount" &&
    (currentSort === SORTING.AMOUNT_ASC || currentSort === SORTING.AMOUNT_DESC)
  ) {
    return currentSort === SORTING.AMOUNT_ASC ? "ASC" : "DESC";
  }
  return "UNSORTED";
};
