/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */

import { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./Title.module.scss";

const cx = classNames.bind(styles);

type TitleProps = {
  level?: 1 | 2 | 3 | 4;
  align?: "left" | "center" | "right";
  usePrimaryColor?: boolean;
  isBoxTitle?: boolean;
  className?: string;
  children?: ReactNode;
};

type HeadingTag = "h1" | "h2" | "h3" | "h4";

const Title = ({
  level = 1,
  align = "left",
  usePrimaryColor = false,
  isBoxTitle = false,
  className = "",
  children,
}: TitleProps) => {
  const Heading = `h${level}` as HeadingTag;

  return (
    <Heading
      className={cx(
        styles.title,
        styles[`titleLevel${level}`],
        styles[align],
        {
          [styles.primaryColor]: usePrimaryColor,
          [styles.boxTitle]: isBoxTitle,
        },
        className
      )}
    >
      {children}
    </Heading>
  );
};

export default Title;
