/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

export const PRIMARY_COLORS = {
  ELECTRICITY_AND_MULTIMEDIA: "#272626",
  ELECTRICITY: "#E6321E",
  MULTIMEDIA: "#0073A3",
};

export const SESSION_STORAGE = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  USER_ID: "USER_ID",
  ROLE: "ROLE",
};

export const ROLES = {
  ADMIN: "Admin",
  USER: "User",
};

export const PAGES = {
  SIGN_IN: "/",
  SIGN_UP: "/sign-up",
  SIGN_UP_CONFIRMATION: "/sign-up-confirmation",
  FORGOTTEN_PASSWORD: "/forgotten-password",
  RESET_PASSWORD: "/reset-password",
  TERMS_OF_SERVICE: "/terms-of-service",
  DATA_PROTECTION: "/data-protection",
  COMING_SOON: "/coming-soon",
  EMAIL_CONFIRM: "/emailconfirm/confirmemail",

  MY_PROFILE: "/my-profile",
  DASHBOARD: "/dashboard",
  MY_INVOICES: "/my-invoices",
  CONTACT_AND_FORMS: "/contact-and-forms",
  MY_CONSUMPTION: "/my-consumption",
  MY_METER_READINGS: "/my-meter-readings",
  MY_ENERGY_OFFER: "/my-energy-offer",
  MY_MULTIMEDIA_OFFER: "/my-multimedia-offer",
  HELP_AND_FAQS: "/help-and-faqs",

  ADMIN_LANDING: "/admin",
  ADMIN_DASHBOARD: "/admin/dashboard",

  ERROR_500: "/error500",
};

export const SCENARIOS = {
  SCENARIO_1: 1,
  SCENARIO_2: 2,
  SCENARIO_3: 3,
  SCENARIO_4: 4,
};

export const SPECIAL_CHARACTERS = [
  "§",
  "±",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "-",
  "_",
  "=",
  "+",
  "[",
  "]",
  ";",
  "'",
  "\\",
  ",",
  ".",
  "/",
  "`",
  "{",
  "}",
  ":",
  '"',
  "|",
  "<",
  ">",
  "?",
  "~",
];

export const MAXIMUM_USER_INACTIVITY_TIME = 600000;
