/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import React from "react";
import { PAGES } from "../../../common/constants/common";
import { Link } from "../../../components/ui";

export const GROUPS = [
  {
    name: "Fourniture d’énergie",
    questions: [
      {
        text: "Comment obtenir les tarifs, les conditions de fourniture et la liste de prix de SIE ?",
        answers: [
          {
            text: [
              "Sur simple demande au 021 631 50 00, par email ou via ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "notre formulaire de contact"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Une panne d’électricité s’est produite sur le réseau de SIE, que faire ?",
        answers: [
          {
            text: [
              "Dans un premier temps, il est impératif de vérifier si la panne provient de votre domicile. Vous pouvez vérifier votre tableau électrique (disjoncteurs et fusibles) ou interroger vos voisins pour savoir si ces derniers sont également impactés. Vous pouvez ensuite contrôler l’état du réseau directement sur le bandeau d’information au sommet de notre site ",
              React.createElement(
                Link,
                { href: "https://www.sie.ch", isLight: true },
                "sie.ch"
              ),
              ". Si la panne est générale dans votre quartier ou immeuble et qu’elle n’est pas déclarée sur notre site, prenez contact avec notre service de dépannage.",
            ],
          },
          {
            text: [
              "La surveillance du réseau électrique de SIE est assurée par le centre de conduite des réseaux de Romande Energie. Les équipes d’intervention sont atteignables, 24h/24 au 0848 802 555. ",
            ],
          },
        ],
      },
      {
        text: "Pourquoi est-ce que je ne signe pas de contrat pour la fourniture de l’électricité ? ",
        answers: [
          {
            text: [
              "Dans le cas de l’utilisation du réseau et de l’approvisionnement en énergie électrique, les rapports juridiques débutent dès que le client s’alimente en électricité ou demande à être alimenté.",
            ],
          },
        ],
      },
      {
        text: "Puis-je gérer plusieurs contrats depuis un seul compte client ?",
        answers: [
          {
            text: [
              "Oui, à condition que chacun des abonnements soit rattaché au même numéro de client, sous la même adresse email.",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Offres et tarifs",
    questions: [
      {
        text: "Comment changer mon offre énergie ?",
        answers: [
          {
            text: [
              "Vous pouvez changer votre offre énergie à tout moment en nous appelant au 021 631 50 00, par ",
              React.createElement(
                Link,
                { href: "mailto:info@sie.ch" },
                "email "
              ),
              " ou via ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "notre formulaire de contact"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Quelles sont les différentes offres d’énergie proposées par SIE ?",
        answers: [
          {
            text: [
              "Vous pouvez consulter nos différentes offres sur notre site : ",
              React.createElement(
                Link,
                { href: "https://www.sie.ch", isLight: true },
                "sie.ch"
              ),
            ],
          },
        ],
      },
      {
        text: "Quel est le prix de l’électricité (kWh) ? ",
        answers: [
          {
            text: [
              "Les prix sont soumis chaque année à la Loi sur l’approvisionnement en électricité (LApEl) et sous contrôle de la Commission fédérale de l’électricité (ElCom). Vous pouvez consulter la grille détaillée des différents tarifs (particuliers, professionnels, communes) sur la page ",
              React.createElement(
                Link,
                {
                  href: "https://www.sie.ch/electricite/tarifs-et-liste-de-prix-267",
                  isLight: true,
                },
                "Tarifs et liste de prix"
              ),
              " de notre site Internet.",
            ],
          },
        ],
      },
      {
        text: "Quelle différence y a-t-il entre un tarif simple et double ? Puis-je changer de tarif ?",
        answers: [
          {
            text: [
              "Le tarif double se distingue par deux prix différents : les heures pleines (HP) et les heures creuses (HC). Selon les tarifs 2022, le tarif en HP est légèrement supérieur à la structure tarifaire simple, alors que celui en HC est très inférieur. Dès 40 % de consommation en HC, il est possible de changer. Si vous souhaitez effectuer un changement de tarif, nous vous recommandons de contacter notre Espace Conseils afin d’évaluer, notamment, la compatibilité de votre compteur.",
            ],
          },
        ],
      },
      {
        text: "Quelle est la différence entre mon profil de consommation (simple, double) et mon offre d’énergie (SIE Nature, SIE Nature +) ?",
        answers: [
          {
            text: [
              "Le tarif Solo définit votre mode de facturation avec un prix unique au kWh. Le tarif Modulo permet l’application d’un tarif variable selon l’heure à laquelle vous consommez l’énergie (heures pleines/heures creuses). En sus des tarifs, deux offres d’énergie sont proposées : SIE Nature et SIE Nature +.",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Déménagement",
    questions: [
      {
        text: "Je viens d’arriver dans mon nouvel appartement et je n’ai pas d’électricité. Que faire ?",
        answers: [
          {
            text: [
              "Lorsqu’un appartement reste vacant après le départ d’un locataire, nous coupons l’électricité. Peut-être avez-vous omis de vous annoncer à nos services ? Nous vous proposons de le faire au plus vite, afin que nous puissions intervenir dans les plus brefs délais. Merci de nous contacter au 021 631 50 00, par ",
              React.createElement(
                Link,
                { href: "mailto:info@sie.ch" },
                "email"
              ),
              ", ou via ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "notre formulaire de contact"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Je déménage ou j’emménage. Que faire ?",
        answers: [
          {
            text: [
              "Tout changement doit être annoncé à SIE. Pour faciliter votre emménagement et garantir une transition rapide, veuillez indiquer votre changement de résidence en remplissant le formulaire ",
              React.createElement(
                Link,
                {
                  href: "https://forms.office.com/Pages/ResponsePage.aspx?id=JkO0EmzDb0Wns3UhIzMTEqwGFn6MSWxGjHqt_V8s229UMDVOTzNOUzRJWVhLMTU2TFZKMEE1TTcxRC4u",
                  target: "_blank",
                },
                "Arrivée"
              ),
              " ou ",
              React.createElement(
                Link,
                {
                  href: "https://forms.office.com/Pages/ResponsePage.aspx?id=JkO0EmzDb0Wns3UhIzMTEqwGFn6MSWxGjHqt_V8s229UMUs5UlhVS0tSR1dLWU0yVTBGS1FDMVpISC4u",
                  target: "_blank",
                },
                "Départ"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Quelle date dois-je annoncer pour mon emménagement/déménagement ?",
        answers: [
          {
            text: [
              "La date annoncée doit être celle de votre état des lieux d’entrée ou de sortie. ",
            ],
          },
        ],
      },
      {
        text: "Comment puis-je effectuer mes démarches pour ma nouvelle habitation ?",
        answers: [
          {
            text: [
              "Retrouvez les différents moyens de nous contacter sur notre page ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "contact et formulaires"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Pourquoi ne puis-je pas annoncer une date d’emménagement de manière rétroactive ?",
        answers: [
          {
            text: [
              "Un délai de deux jours (hors week-end et jours fériés) est obligatoire en amont de votre arrivée/départ afin d’effectuer un relevé de compteur et d’activer/de terminer votre contrat.",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Paiements et facturation",
    questions: [
      {
        text: "Puis-je demander un report d’échéance pour le paiement des factures ?",
        answers: [
          {
            text: [
              "Il est possible de demander un report d’échéance de votre facture pour un mois en nous contactant, ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "contact et formulaires"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Puis-je payer ma facture en ligne ? ",
        answers: [
          {
            text: [
              "Oui, nous vous offrons de recevoir votre facture par eBill.",
            ],
          },
        ],
      },
      {
        text: "Pourquoi est-ce que je ne peux pas faire un ordre permanent depuis mon e-banking ?",
        answers: [
          {
            text: [
              "Chaque facture est unique et dispose de son propre numéro de référence. L’ordre permanent ne permet donc pas de tracer votre paiement de manière automatique et peut engendrer des problèmes dans votre comptabilité . Dès lors, nous vous conseillons d’activer l’option eBill afin d’effectuer vos paiements en un clic. Pour son activation, veuillez vous rendre directement sur votre portail bancaire ou postal en ligne.",
            ],
          },
        ],
      },
      {
        text: "J’ai payé deux fois la même facture, que puis-je faire ?",
        answers: [
          {
            text: [
              "Si vous avez réglé votre facture à double, le surplus perçu est déduit de votre prochaine facture ou remboursé à condition de nous communiquer vos coordonnées bancaires.",
            ],
          },
        ],
      },
      {
        text: "Comment sont gérés les clients sous curatelle ou tutelle ?",
        answers: [
          {
            text: [
              "En cas de curatelle ou tutelle, le tuteur prend la place du pupille et agit comme s’il était le client.",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Relevés de compteurs",
    questions: [
      {
        text: "Qu’est-ce qu’un compteur intelligent (ou smart meter) ?",
        answers: [
          {
            text: [
              "Un smart meter est un compteur intelligent qui offre la possibilité de recevoir et de transmettre des informations à distance. Il mesure et enregistre l’énergie consommée et produite. Il permet de calculer des courbes de charge avec une période de mesure de quinze minutes.",
            ],
          },
        ],
      },
      {
        text: "Comment lire mon compteur pour vous fournir les relevés ou suivre ma consommation ?",
        answers: [
          {
            text: [
              "Les modèles de compteurs sont nombreux. Si vous rencontrez des difficultés à lire votre compteur, contactez-nous : ",
            ],
          },
          {
            text: [
              "• au 021 631 50 00, du lundi au vendredi : 8h – 12h / 13h – 17h",
            ],
          },
          { text: ["• par email : info@sie.ch"] },
          {
            text: [
              "• à l’Espace Conseils, rue Neuve 5 à Renens, du lundi au vendredi : 8h – 18h et le samedi 9h – 13h30",
            ],
          },
          { text: ["• par courrier : SIA SA, case postale, 1023 Crissier"] },
          {
            text: [
              "• via notre ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "contact et formulaires"
              ),
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Multimédia",
    questions: [
      {
        text: "Vous souhaitez mettre en service votre prise TV ?",
        answers: [
          {
            text: [
              "Il vous suffit de prendre contact avec nous au 021 631 50 00 ou de vous rendre directement à notre ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                "Espace Conseils à Renens"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Où et comment puis-je faire mon inscription afin d’obtenir des services supplémentaires, des chaînes de télévision payantes, une connexion Internet, un téléphone fixe ou mobile ?",
        answers: [
          {
            text: [
              "Si vous n’avez pas encore souscrit un abonnement avec notre partenaire, nous vous proposons de vous rendre directement à notre ",
              React.createElement(
                Link,
                { to: PAGES.CONTACT_AND_FORMS, isLight: true },
                " Espace Conseils à Renens"
              ),
              " afin d’y effectuer votre inscription. ",
              "Vous pouvez aussi commander vos abonnements via ces formulaires : ",
              React.createElement(
                Link,
                {
                  href: "https://form.jotform.com/223181969496370",
                  isLight: true,
                },
                "Abonnement Mobile"
              ),
              " / ",
              React.createElement(
                Link,
                {
                  href: "https://form.jotform.com/223181969496370",
                  isLight: true,
                },
                "Abonnement Home"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Comment faire pour être raccordé au réseau multimédia ?",
        answers: [
          {
            text: [
              "Pour autant que le réseau multimédia est installé dans votre immeuble, la prise devrait être en service et facturée dès votre entrée dans l’appartement.",
            ],
          },
        ],
      },
      {
        text: "Où puis-je trouver les conditions générales relatives à la mise hors service du raccordement multimédia ?",
        answers: [
          {
            text: [
              "Dans l’hypothèse où la prise serait plombée, nous vous prions de prendre contact avec notre service au 021 631 50 00 afin de la faire contrôler ou de la déplomber. Si l’immeuble n’est pas raccordé au réseau multimédia, consultez nos conditions pour le raccordement d’un bâtiment.",
            ],
          },
          {
            text: [
              "Nos conditions générales relatives à la mise hors service du raccordement multimédia sont disponibles sur ",
              React.createElement(
                Link,
                {
                  href: "https://www.sie.ch/media/document/0/tvt-cg-mise-hors-service-prise-v6.pdf",
                  isLight: true,
                },
                "ce lien"
              ),
              ".",
            ],
          },
        ],
      },
      {
        text: "Déménagement hors réseau SIE/TVT, comment dois-je procéder ?",
        answers: [
          {
            text: [
              "Veuillez nous contacter au 021 631 50 00 afin de procéder à votre annonce de déménagement, tant pour l’électricité que pour le raccordement multimédia.",
            ],
          },
          {
            text: [
              "Dans le cas où votre nouvelle adresse ne ferait pas partie du réseau Sunrise, il faudra de plus résilier votre abonnement par voie postale en envoyant un courrier recommandé à Sunrise Sàrl, case postale, 8152 Zürich.",
            ],
          },
        ],
      },
      {
        text: "Pourquoi dois-je payer le raccordement multimédia alors que je paie déjà une redevance Serafe ?",
        answers: [
          {
            text: [
              "Serafe (anciennement Billag) est l’organe suisse d’encaissement des redevances de réception des programmes de radio et de télévision.",
            ],
          },
          {
            text: [
              "Serafe n’a aucun lien avec le réseau multimédia ou les produits TVT Services.",
            ],
          },
          {
            text: [
              "Le réseau multimédia est constitué d’infrastructures câblées qui distribuent les signaux hertziens numériques réceptionnés par nos installations d’antennes.",
            ],
          },
        ],
      },
      {
        text: "Je cherche d’autres informations liées au multimédia ou Internet.",
        answers: [
          {
            text: [
              "Vous pouvez retrouver d’autres questions et réponse sur notre site Internet, ",
              React.createElement(
                Link,
                {
                  href: "https://www.sie.ch/multimedia/faq-266",
                  isLight: true,
                },
                "page Multimédia - FAQ"
              ),
              ".",
            ],
          },
        ],
      },
    ],
  },
];
