/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */

import { MouseEventHandler, useRef } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon, CloseIcon } from "../../../../assets/icons";
import { SPECIAL_CHARACTERS } from "../../../../common/constants/common";
import { Button, Col, Input, Row } from "../../../../components/ui";
import { useAppDispatch } from "../../../../redux/hooks";
import { updatePassword } from "../../auth.actions";
import trackClickHandler from "../../../../common/helpers/trackClickHandler";
import styles from "../MyProfileForms.module.scss";

type PasswordsFormProps = {
  isEditing?: boolean;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: Function;
};

const PasswordsForm = ({
  isEditing = false,
  onEdit = () => {},
  onCancel = () => {},
}: PasswordsFormProps) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    shouldUnregister: true,
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  const onSubmit = (data) => {
    dispatch(
      updatePassword({
        data: { oldPassword: data.oldPassword, newPassword: data.newPassword },
        callback: () => {
          onCancel();
          reset();
        },
        setError,
      })
    );
  };

  return (
    <Row align="start" direction="column">
      <Col span={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row wrapForMobile align="end" justify="space-between">
            <Col className={styles.formRowSpaceBottom} span={12}>
              <Input
                {...register("oldPassword", {
                  required: true,
                })}
                error={
                  (errors.oldPassword?.type === "required" &&
                    "L'ancien mot de passe est requis") ||
                  (errors.oldPassword?.type === "incorrectPassword" &&
                    "Ancien mot de passe est incorrect")
                }
                label="Ancien mot de passe"
                readOnly={!isEditing}
                type="password"
                onFocus={trackClickHandler}
              />
            </Col>
          </Row>
          <Row wrapForMobile align="end" justify="space-between">
            <Col className={styles.formRowSpaceBottom} span={12}>
              <Input
                {...register("newPassword", {
                  minLength: 8,
                  validate: {
                    hasLowerCase: (value) => /[a-z]+/.test(value),
                    hasUpperCase: (value) => /[A-Z]+/.test(value),
                    hasNumber: (value) => /[0-9]+/.test(value),
                    hasSpecialCharacter: (value) =>
                      SPECIAL_CHARACTERS.some((specialCharacter) =>
                        value.includes(specialCharacter)
                      ),
                  },
                })}
                error={
                  (errors.newPassword?.type === "required" &&
                    "Mot de passe requis") ||
                  (errors.newPassword?.type === "minLength" &&
                    "Votre mot de passe ne répond pas aux exigences de sécurité demandées: être composé au minimum de 8 caractères.") ||
                  (errors.newPassword?.type === "hasLowerCase" &&
                    "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre minuscule latine (de a à z).") ||
                  (errors.newPassword?.type === "hasUpperCase" &&
                    "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre majuscule latine (de A à Z).") ||
                  (errors.newPassword?.type === "hasNumber" &&
                    "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un chiffre (de 0 à 9).") ||
                  (errors.newPassword?.type === "hasSpecialCharacter" &&
                    "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un caractère spécial (exemple : ?, !).")
                }
                label="Nouveau mot de passe"
                readOnly={!isEditing}
                type="password"
                onFocus={trackClickHandler}
              />
            </Col>
          </Row>
          <Row wrapForMobile align="end" justify="space-between">
            <Col className={styles.formRowSpaceBottom} span={12}>
              <Input
                {...register("newPasswordConfirm", {
                  required: true,
                  validate: (value) => value === newPassword.current,
                })}
                error={
                  (errors?.newPasswordConfirm?.type === "required" &&
                    "Veuillez confirmer le mot de passe") ||
                  (errors?.newPasswordConfirm?.type === "validate" &&
                    "Les mots de passe ne correspondent pas")
                }
                label="Confirmer le nouveau mot de passe"
                readOnly={!isEditing}
                type="password"
                onFocus={trackClickHandler}
              />
            </Col>
          </Row>
          {!isEditing && (
            <Row justify="end">
              <Button onClick={onEdit}>Éditer</Button>
            </Row>
          )}
          {isEditing && (
            <Row className={styles.editButtons} justify="end">
              <Button
                className={styles.closeIcon}
                type="button"
                onClick={() => {
                  onCancel();
                  reset();
                }}
              >
                <CloseIcon />
              </Button>
              <Button
                className={styles.checkIcon}
                type="submit"
                onClick={trackClickHandler}
              >
                <CheckIcon />
              </Button>
            </Row>
          )}
        </form>
      </Col>
    </Row>
  );
};

export default PasswordsForm;
