/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useState } from "react";
import { Button, Row, Title, Text, Underlay } from "../../ui";
import {
  HeaderBurgerBarIcon,
  MecHeaderIcon,
  SieTvtHeaderIcon,
  SmallArrowIcon,
  UserIcon,
} from "../../../assets/icons";
import { PAGES } from "../../../common/constants/common";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { signOut } from "../../../modules/auth/auth.actions";
import { userSelector } from "../../../modules/auth/auth.selectors";
import styles from "./Header.module.scss";

const cx = classNames.bind(styles);

type HeaderProps = {
  type?: "public" | "private" | "admin";
  onToggleMobileNavigation?: Function;
  className?: string;
};

const Header = ({
  type = "private",
  onToggleMobileNavigation = () => {},
  className = "",
}: HeaderProps) => {
  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const history = useHistory();
  const { pathname } = useLocation();

  const isComingSoon = String(pathname).includes("coming");

  const publicHeaderTitle = () => {
    switch (pathname) {
      case PAGES.TERMS_OF_SERVICE:
        return "Conditions générales d’utilisation";
      case PAGES.DATA_PROTECTION:
        return "Protection des données";
      case PAGES.COMING_SOON:
        return "";
      default:
        return "Bienvenue";
    }
  };

  const privateHeader = (
    <>
      <HeaderBurgerBarIcon
        className={styles.burgerBar}
        onClick={onToggleMobileNavigation}
      />
      <Button
        className={styles.userInfo}
        onClick={() => setShowHeaderDropdown(!showHeaderDropdown)}
      >
        {user?.name} {user?.lastName}
        <SmallArrowIcon
          className={cx(styles.smallArrowIcon, {
            [styles.smallArrowIconDropdownOpenned]: showHeaderDropdown,
          })}
        />
        <span className={styles.userIconWrapper}>
          <UserIcon className={styles.userIcon} />
        </span>
      </Button>

      {showHeaderDropdown && (
        <>
          <Underlay
            opacity={0.1}
            onClick={() => setShowHeaderDropdown(false)}
          />
          <div className={styles.headerDropdown}>
            <Link
              className={styles.headerDropdownItem}
              to={PAGES.MY_PROFILE}
              onClick={() => setShowHeaderDropdown(false)}
            >
              Mon profil
            </Link>
            <Button
              className={styles.headerDropdownItem}
              onClick={() => dispatch(signOut())}
            >
              Se déconnecter
            </Button>
          </div>
        </>
      )}
      {type === "admin" && (
        <Row align="center" className={styles.adminBanner} justify="center">
          <Text className={styles.adminBannerText}>
            Vous êtes authentifié en tant que administrateur
          </Text>
        </Row>
      )}
    </>
  );

  const publicHeader = (
    <>
      <MecHeaderIcon
        className={styles.mecPublicHeaderIcon}
        onClick={(event) => {
          if (isComingSoon) {
            return;
          }
          // eslint-disable-next-line no-unused-expressions
          user ? history.push(PAGES.DASHBOARD) : history.push(PAGES.SIGN_IN);
        }}
      />
      <Title level={2}>{publicHeaderTitle()}</Title>
      <SieTvtHeaderIcon className={styles.sieTvtPublicHeaderIcon} />
    </>
  );

  return (
    <header
      className={cx(
        {
          [styles.publicHeader]: type === "public",
          [styles.privateHeader]: type !== "public",
          [styles.adminHeader]: type === "admin",
        },
        className
      )}
      id="header"
    >
      {type === "public" ? publicHeader : privateHeader}
    </header>
  );
};

export default Header;
