/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
import { useEffect, useState } from "react";
import { SieRedIcon, TvtBlueIcon } from "../../../assets/icons";
import EnergyOfferImage from "../../../assets/images/energy.jpg";
import InternetAndMultimediaImage from "../../../assets/images/internet-and-multimedia.jpg";
import { PAGES } from "../../../common/constants/common";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import trackHistory from "../../../common/helpers/trackHistory";
import {
  Col,
  Link,
  Loader,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import ConsumptionGraph from "../../../components/ui/consumptionGraph/ConsumptionGraph";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchElectricityOffer } from "../../auth/auth.actions";
import {
  electricityOfferSelector,
  userSelector,
} from "../../auth/auth.selectors";
import {
  INTERNET_COAXAL,
  INTERNET_OPTIQ,
} from "../../multimedia/myMultimediaOffer/service/myMultimediaOffer.constants";
import { myMultimediaOfferSelector } from "../../multimedia/myMultimediaOffer/service/myMultimediaOffer.selectors";
import styles from "./Dashboard.module.scss";
import { fetchDashboardData } from "./service/dashboard.actions";
import {
  consumptionDashboardSelector,
  electricityContractDashboardSelector,
  isComplexDashboardSelector,
  isFetchingDashboardSelector,
  isFetchingLastInvoiceAndElectricityContractSelector,
  lastInvoiceDashboardSelector,
  multimediaContractDashboardSelector,
} from "./service/dashboard.selectors";
import ServicesAndNewsletter from "./ServicesAndNewsletter";

const Dashboard = () => {
  const location = window.location.href;
  const { scenario, id } = useAppSelector(userSelector);
  const isComplex = useAppSelector(isComplexDashboardSelector);
  const isFetching = useAppSelector(isFetchingDashboardSelector);
  const isFetchingLastInvoiceAndElectricityContract = useAppSelector(
    isFetchingLastInvoiceAndElectricityContractSelector
  );
  const lastInvoice = useAppSelector(lastInvoiceDashboardSelector);
  const electricityContract = useAppSelector(
    electricityContractDashboardSelector
  );
  const multimediaContract = useAppSelector(
    multimediaContractDashboardSelector
  );
  const consumptionData = useAppSelector(consumptionDashboardSelector);
  const dispatch = useAppDispatch();
  const electricityOffer = useAppSelector(electricityOfferSelector);
  const [offer, setOffer] = useState<string | undefined>();
  const [mediaOffer, setMediaOffer] = useState<string | undefined>();

  // CODE FOR MULTIMEDIA ET INTERNET???
  const addresses = useAppSelector(myMultimediaOfferSelector);
  const [selectedAddress, setSelectedAddress] = useState({} as any);
  const internetCoaxal = multimediaContract?.internetCoaxal;
  const internetOptiq = multimediaContract?.internetOptiq;

  useEffect(() => {
    setSelectedAddress(addresses[0]);
  }, [addresses]);

  useEffect(() => {
    dispatch(fetchDashboardData());
    dispatch(fetchElectricityOffer());
    return () => {
      trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
      sessionStorage.removeItem("clicks");
    };
  }, []);

  // Set energy offer in my energy offer
  const myEnergyOffer = () => {
    if (offer?.includes("Nature")) {
      if (offer.includes("+")) {
        return "Vous avez accès à notre offre 50 % énergie hydraulique, 50 % énergie photovoltaïque locale.";
      }
      return "Vous avez accès à notre offre 100 % énergie hydraulique.";
    }
    if (offer === "SIE Basic") {
      return "Vous avez accès à notre offre basique, 100 % énergie nucléaire.";
    }
    if (offer === "SIE Force Hydro EU") {
      return "Vous avez accès à notre offre 100 % énergie hydraulique européenne.";
    }
    if (offer === "SIE Force Hydro +") {
      return "Vous avez accès à notre offre 100 % énergie hydraulique suisse.";
    }
    if (offer === "SIE Personnalisable") {
      return "Vous avez accès à une offre sur mesure pour vos besoins.";
    }

    return "Vous avez accès à une offre sur mesure pour vos besoins.";
  };

  useEffect(() => {
    setOffer(electricityOffer?.econtractType);
    setMediaOffer(electricityOffer?.mcontractType);
  }, [electricityOffer]);

  if (isFetching && isFetchingLastInvoiceAndElectricityContract) {
    return <Loader />;
  }

  const renderInternetAndMultimediaContent = () => {
    // #1, internetOptiq === INTERNET_OPTIQ.ENABLED (Activé)
    if (internetOptiq === INTERNET_OPTIQ.ENABLED) {
      return (
        <>
          <Text align="center">
            Vous êtes abonné aux services de notre partenaire.
          </Text>
          <Separator size="small" />
          <Link
            isTvt
            align="center"
            href="https://www.sie.ch/multimedia/commander-votre-offre/"
            onClick={trackClickHandler}
          >
            Actualisez votre offre
          </Link>
        </>
      );
    }

    // #2, internetOptiq === INTERNET_OPTIQ.AVAILABLE (Disponible)
    if (internetOptiq === INTERNET_OPTIQ.AVAILABLE) {
      return (
        <>
          <Text align="center">
            TVT Services propose un service de connexion Internet et multimédia
            via la fibre optique et le raccordement coaxial.
          </Text>
          <Separator size="small" />
          <Text align="center">
            Une connexion Internet 10 Gbit/s disponible à votre adresse.
          </Text>
          <Separator size="small" />
          <Link
            isLight
            align="center"
            className={styles.blue}
            href="https://www.sie.ch/multimedia/commander-votre-offre"
            onClick={trackClickHandler}
          >
            Profitez du meilleur divertissement avec TVT
          </Link>
        </>
      );
    }

    // #3, internetCoaxal === INTERNET_COAXAL.OPEN (Ouvert)
    if (internetCoaxal === INTERNET_COAXAL.OPEN) {
      return (
        <>
          <Text align="center">
            Vous profitez d’une offre multimédia 3 en 1, TVT Basic.
          </Text>
          <Separator size="small" />
          <Text align="center">
            Plus d’informations concernant&nbsp;
            <Link
              isTvt
              href="https://www.sie.ch/multimedia/commander-votre-offre/"
              onClick={trackClickHandler}
            >
              votre raccordement multimédia
            </Link>
            .
          </Text>
          <Separator size="small" />
          <Link
            isTvt
            align="center"
            href="https://www.sie.ch/multimedia/commander-votre-offre/"
            onClick={trackClickHandler}
          >
            Offres promotionnelles (réservées aux nouveaux clients)
          </Link>
        </>
      );
    }

    // #4, internetCoaxal === INTERNET_COAXAL.LEADED (Plombé)
    if (internetCoaxal === INTERNET_COAXAL.LEADED) {
      return (
        <>
          <Text align="center">
            TVT Services propose un service de connexion Internet et multimédia
            via la fibre optique et le raccordement coaxial.
          </Text>
          <Separator size="small" />
          <Text align="center">
            Une connexion Internet 1 Gbit/s disponible à votre adresse.
          </Text>
          <Separator size="small" />
          <Link
            isLight
            align="center"
            className={styles.blue}
            href="https://www.sie.ch/multimedia/commander-votre-offre"
            onClick={trackClickHandler}
          >
            Profitez du meilleur divertissement avec TVT
          </Link>
        </>
      );
    }

    // #5, internetCoaxal === INTERNET_COAXAL.UNAVAILABLE (Indisponible)
    return (
      <Text align="center">
        Aucun raccordement n’est disponible à votre adresse. Pour plus
        d’informations, n’hésitez pas&nbsp;
        <Link
          isLight
          className={styles.blue}
          href="https://www.sie.ch/multimedia/commander-votre-offre"
          onClick={trackClickHandler}
        >
          prendre contact avec nous
        </Link>
        .
      </Text>
    );
  };

  const renderEnergieContent = () => {
    if (scenario === 2) {
      return (
        <>
          <Title isBoxTitle className={styles.red} level={3}>
            Abonnez-vous à SIE
          </Title>
          <Separator />
          <img
            alt="energyOfferImage"
            className={styles.energyOfferImage}
            src={EnergyOfferImage}
          />
          <Separator />
          <Text>SIE, le fournisseur d’énergie régionale à votre service.</Text>
          <Separator size="small" />
          <Link
            className={styles.red}
            href="https://www.sie.ch/"
            onClick={trackClickHandler}
          >
            Découvrez nos offres d’énergies renouvelables et locales
          </Link>
          <Separator size="small" />
          <Text>
            Nos offres sont disponibles sur les communes de
            Chavannes-près-Renens, Crissier, Écublens et Renens.
          </Text>
        </>
      );
    }
    if (scenario !== 2 && !isComplex) {
      return (
        <>
          <Title isBoxTitle className={styles.red} level={3}>
            Mon offre énergie
          </Title>
          <Separator />
          <img
            alt="energyOfferImage"
            className={styles.energyOfferImage}
            src={EnergyOfferImage}
          />
          <Separator />
          <Text align="center">
            {/* {ENERGY_OFFERS[electricityContract?.econtractType] || 'Vous avez accès à notre offre 100% énergie hydraulique.'} */}
            {myEnergyOffer()}
          </Text>
          <Link
            className={styles.red}
            to={PAGES.MY_ENERGY_OFFER}
            onClick={trackClickHandler}
          >
            En savoir plus
          </Link>
        </>
      );
    }
    return (
      <>
        <Title isBoxTitle className={styles.red} level={3}>
          Mon offre énergie
        </Title>
        <Separator />
        <img
          alt="energyOfferImage"
          className={styles.energyOfferImage}
          src={EnergyOfferImage}
        />
        <Separator />
        <Text align="center">{myEnergyOffer()}</Text>
        <Link
          isLight
          className={styles.red}
          to={PAGES.MY_ENERGY_OFFER}
          onClick={trackClickHandler}
        >
          En savoir plus
        </Link>
      </>
    );
  };

  return (
    <Row align="start" className={styles.dashboard} direction="column">
      <Col>
        <Title>Tableau de bord</Title>
      </Col>
      <Separator />
      <Col span={12}>
        <Row wrapForMobile align="stretch" justify="space-between" wrap={false}>
          {/* BLOCK 1 */}
          <Col isContentBox className={styles.lastInvoice} md={7} span={12}>
            <Title isBoxTitle level={3}>
              Dernière facture
            </Title>
            {lastInvoice?.amount && (
              <>
                <Text>Facture pour la dernière période</Text>
                <Title className={styles.invoicePrice}>
                  <>
                    {lastInvoice.amount}
                    <div className={styles.currency}>CHF</div>
                  </>
                </Title>
                <Link
                  isLight
                  className={styles.invoicesLink}
                  to={PAGES.MY_INVOICES}
                  onClick={trackClickHandler}
                >
                  Voir mes factures
                </Link>
              </>
            )}
            {!lastInvoice?.amount && (
              <Title align="center" className={styles.invoicePrice} level={3}>
                Vous n’avez pas encore de facture disponible.
              </Title>
            )}
          </Col>
          {/* BLOCK 2 */}
          <Col isContentBox md={5} span={12}>
            <Title isBoxTitle level={3}>
              Mes informations client
            </Title>
            <Text>Numéro client : {id}</Text>
            <Separator size="small" />
            <Link
              isLight
              to={PAGES.MY_ENERGY_OFFER}
              onClick={trackClickHandler}
            >
              Offre énergie:
            </Link>
            <Separator size="small" />
            <Text align="center">
              {/* {energyOffer()} */}
              {!offer ? "Aucune" : offer}
            </Text>
            <Separator size="small" />
            <Link
              isLight
              to={PAGES.MY_MULTIMEDIA_OFFER}
              onClick={trackClickHandler}
            >
              Offre multimédia:
            </Link>
            <Separator size="small" />
            <Text>
              {mediaOffer ?? "Aucune"}
            </Text>
          </Col>
        </Row>
        {/* maximum number of api calls for this page is 5,
        to avoid showing only loader untill everything is loaded, first 2 blocks can be shown after 3 api calls */}
        {isFetching ? (
          <Loader type="content" />
        ) : (
          <>
            <Separator />
            <Row wrapForMobile align="stretch" justify="space-between">
              {/* BLOCK 3 and 4 */}
              <Col className={styles.energieAndConsumptionBox} md={7} span={12}>
                <Row
                  align="start"
                  className={styles.height100}
                  direction="column"
                  justify="start"
                >
                  <SieRedIcon className={styles.sieIcon} />
                  {/* BLOCK 3 */}
                  <Col isContentBox span={12}>
                    {renderEnergieContent()}
                  </Col>
                  {/* BLOCK 4 */}
                  {scenario !== 2 && (
                    <>
                      <Separator size="large" />
                      <ConsumptionGraph data={consumptionData} />
                    </>
                  )}
                </Row>
              </Col>
              {/* BLOCK 5 */}
              <Col md={5} span={12}>
                <Row
                  align="start"
                  className={styles.height100}
                  direction="column"
                  justify="start"
                >
                  <TvtBlueIcon className={styles.tvtIcon} />
                  <Col
                    isContentBox
                    className={styles.internetAndMultimediaBox}
                    span={12}
                  >
                    <Title isBoxTitle className={styles.blue} level={3}>
                      Internet et multimédia
                    </Title>
                    <Separator />
                    <img
                      alt="internet-and-multimedia"
                      className={styles.internetAndMultimediaImage}
                      src={InternetAndMultimediaImage}
                    />
                    <Separator size="large" />
                    <Text align="center">
                      TVT Services propose un service de connexion internet et
                      multimédia via la fibre optique et raccordement coaxial.{" "}
                    </Text>
                    <Separator />
                    <Link
                      className={styles.blue}
                      href="https://www.sie.ch/multimedia/commander-votre-offre/"
                      onClick={trackClickHandler}
                    >
                      En savoir plus
                    </Link>
                    <Separator />
                    {renderInternetAndMultimediaContent()}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Separator size="large" />
            {/* BLOCK 6 */}
            <ServicesAndNewsletter />
          </>
        )}
      </Col>
    </Row>
  );
};

export default Dashboard;
