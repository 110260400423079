/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./Radio.module.scss";

const cx = classNames.bind(styles);

type RadioProps = {
  id: string;
  name?: string;
  value?: string | number;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
};

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id = "radioId",
      name = "radioName",
      value = "radioValue",
      onChange = () => {},
      defaultChecked = false,
      disabled = false,
      className = "",
      labelClassName = "",
      children,
    }: RadioProps,
    forwardedRef
  ) => (
    <div className={cx(styles.radioWrapper, className)}>
      <label className={styles.radioAndLabel} htmlFor={id}>
        <div className={cx(styles.radio, { [styles.radioDisabled]: disabled })}>
          <input
            defaultChecked={defaultChecked}
            disabled={disabled}
            id={id}
            name={name}
            ref={forwardedRef}
            type="radio"
            value={value}
            onChange={onChange}
          />
          <div
            className={cx(styles.radioCircle, {
              [styles.radioCircleDisabled]: disabled,
            })}
          />
          <div className={styles.radioCheckedBorder} />
        </div>
        {children && (
          <div className={cx(styles.radioLabel, labelClassName)}>
            {children}
          </div>
        )}
      </label>
    </div>
  )
);

export default Radio;
