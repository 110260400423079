/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios from "axios";
import { SESSION_STORAGE, PAGES } from "../common/constants/common";
// eslint-disable-next-line
import { signOut } from "../modules/auth/auth.actions";
import store from "../redux/store";
import globalConfig from "../common/config/global.config";
import { history, toastError } from "../services";

const createRequestInterceptorResolve = (config) => {
  const accessToken = sessionStorage.getItem(SESSION_STORAGE.ACCESS_TOKEN);

  const commonHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key":
      globalConfig.API_HEADER_OCP_APIM_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": globalConfig.API_HEADER_OCP_APIM_TRACE,
    Authorization: "",
  };

  if (accessToken && accessToken !== "null") {
    commonHeaders.Authorization = `Bearer ${accessToken}`;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...commonHeaders,
    },
  };
};

const createResponseInterceptorReject = (error) => {
  switch (error.response?.status) {
    case 401:
      store.dispatch(signOut());
      toastError({ message: "Non autorisé" });
      return Promise.reject(error);
    case 500:
      history.push(PAGES.ERROR_500);
      return Promise.reject(error);
    default:
      return Promise.reject(error);
  }
};

const axiosInstance = axios.create({
  baseURL: `${globalConfig.API_URL}`,
});

axiosInstance.interceptors.request.use(
  createRequestInterceptorResolve,
  () => {}
);

axiosInstance.interceptors.response.use(
  (response) => response,
  createResponseInterceptorReject
);

export const { CancelToken } = axios;
export default axiosInstance;
