/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { ReactNode } from "react";
import { Col, Text, Title } from "../../../components/ui";
import styles from "./TermsOfService.module.scss";

type SectionProps = {
  title?: string | ReactNode;
  paragraphs?: string[] | ReactNode[];
  subtitle?: string | ReactNode;
  other?: string | ReactNode;
};

const Section = ({
  title = "",
  paragraphs = [],
  subtitle = "",
  other = "",
}: SectionProps) => (
  <Col>
    {title && (
      <Title className={styles.title} level={2}>
        {title}
      </Title>
    )}
    {subtitle && (
      <Title className={styles.title} level={3}>
        {subtitle}
      </Title>
    )}
    {paragraphs.map((paragraph, index) => (
      // eslint-disable-next-line
      <Text key={index}>{paragraph}</Text>
    ))}
    {other && other}
  </Col>
);

export default Section;
