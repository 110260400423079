/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import axios, { AxiosResponse } from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { PAGES } from "../../../common/constants/common";
import {
  Button,
  Col,
  Input,
  Link,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { signIn } from "../public.actions";
import { isSubmittingPublicSelector } from "../public.selectors";
import {
  confirmAccountApi,
} from "../../../api/public.api";
import { toastError, toastSuccess } from "../../../services";
import { signInRequestData, confirmEmailData } from "../public.types";
import styles from "./SignIn.module.scss";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const isSubmitting = useAppSelector(isSubmittingPublicSelector);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    shouldUnregister: true,
  });

  const onSignIn = (data: signInRequestData) => {
    dispatch(signIn(data, setError, reset));
  };

  return (
    <Row className={styles.signIn} direction="column">
      <Col lg={6} md={8} span={10} xl={3}>
        <Title align="center" level={1}>
          Accéder à Mon Espace Client{" "}
        </Title>
        <Text align="center" size="large">
          Connectez-vous pour suivre vos consommations, factures et relevés de
          compteurs
        </Text>
        <Separator size="large" />
      </Col>
      <Col lg={4} md={5} span={10} xl={3} xxl={2}>
        <form onSubmit={handleSubmit(onSignIn)}>
          <Separator />
          <Input
            {...register("email", { required: true })}
            isLabelBolded
            error={
              (errors.email?.type === "required" && "Email est requis") ||
              (errors.email?.type === "incorrectEmail" &&
                "Email ou mot de passe incorrect")
            }
            label="Email"
            type="email"
          />
          <Separator />
          <Input
            {...register("password", { required: true })}
            isLabelBolded
            error={
              (errors.password?.type === "required" && "Mot de passe requis") ||
              (errors.password?.type === "incorrectPassword" &&
                "Email ou mot de passe incorrect")
            }
            label="Mot de passe"
            type="password"
          />
          <Link
            className={styles.forgottenPasswordLink}
            to={PAGES.FORGOTTEN_PASSWORD}
          >
            Mot de passe oublié?
          </Link>
          <Separator />
          <Row>
            <Button
              buttonStyle="secondaryButton"
              className={styles.signInSecondaryButton}
              type="button"
              onClick={() => reset()}
            >
              Annuler
            </Button>
            <Button
              className={styles.signInButton}
              loading={isSubmitting}
              type="submit"
            >
              Valider
            </Button>
          </Row>
        </form>
        <Separator size="large" />
      </Col>
      <Col
        className={styles.signUpBoxWrapper}
        lg={6}
        md={7}
        sm={8}
        span={10}
        xl={7}
      >
        <div className={styles.signUpBox}>
          <Title align="center" level={1}>
            Créer mon compte
          </Title>
          <Text align="center" size="large">
            Créer mon Espace Client en quelques clics
            <br />
            et simplifier la gestion de mes offres SIE / TVT.
          </Text>
          <Separator size="large" />
          <Button
            className={styles.signUpButton}
            type="button"
            onClick={() => history.push(PAGES.SIGN_UP)}
          >
            Créer mon compte
          </Button>
        </div>

        <div className={styles.signInFooter}>
          <Row>
            <Col lg={6} md={5} span={10} xl={6}>
              <Text isBold className={styles.footerLinkQuestion} size="large">
                Pas encore client ?<br />
                Souscrire à une offre&nbsp;
                <Link className={styles.redLink} href="https://www.sie.ch/">
                  énergie
                </Link>
                &nbsp;ou&nbsp;
                <Link
                  className={styles.blueLink}
                  href="https://www.tvtservices.ch/"
                >
                  multimédia
                </Link>
                .
              </Text>
              <Separator />
              <Text size="large">
                Données personnelles et conditions générales d&apos;utilisation
              </Text>
              <Separator size="small" />
              <Text className={styles.personalDataText} size="small">
                Pour plus d’informations sur le traitement de vos données
                personnelles, nous vous invitons à vous référer à&nbsp;
                <Link
                  className={styles.privacyPolicyLink}
                  target="_blank"
                  to={PAGES.DATA_PROTECTION}
                >
                  notre charte de protection des données personnelles.
                </Link>
              </Text>
              <Separator size="small" />
              <Text isLight>
                L&rsquo;utilisation du portail client vaut comme acceptation des
                <Link
                  className={styles.privacyPolicyLink}
                  target="_blank"
                  to={PAGES.TERMS_OF_SERVICE}
                >
                  &nbsp;conditions générales d’utilisation.
                </Link>
              </Text>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SignIn;
