/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./Col.module.scss";

const cx = classNames.bind(styles);

type ColProps = {
  span?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  isContentBox?: boolean;
  hasPageContentOffset?: boolean;
  hasPageContentLeftOffset?: boolean;
  hasPageContentRightOffset?: boolean;
  className?: string;
  children?: ReactNode;
};

const Col = ({
  span = -1,
  sm = -1,
  md = -1,
  lg = -1,
  xl = -1,
  xxl = -1,
  isContentBox = false,
  hasPageContentOffset = false,
  hasPageContentLeftOffset = false,
  hasPageContentRightOffset = false,
  className = "",
  children,
}: ColProps) => (
  <div
    className={cx(
      styles.col,
      {
        [styles[`col-span-${span}`]]: span !== -1,
        [styles[`col-sm-${sm}`]]: sm !== -1,
        [styles[`col-md-${md}`]]: md !== -1,
        [styles[`col-lg-${lg}`]]: lg !== -1,
        [styles[`col-xl-${xl}`]]: xl !== -1,
        [styles[`col-xxl-${xxl}`]]: xxl !== -1,
        [styles.contentBox]: isContentBox,
        [styles.hasPageContentOffset]: hasPageContentOffset,
        [styles.hasPageContentLeftOffset]: hasPageContentLeftOffset,
        [styles.hasPageContentRightOffset]: hasPageContentRightOffset,
      },
      className
    )}
  >
    {children}
  </div>
);

export default Col;
