/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./Checkbox.module.scss";

const cx = classNames.bind(styles);

type ControlledCheckboxProps = {
  id: string;
  name?: string;
  checked?: boolean;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasDarkBorder?: boolean;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
};

const ControlledCheckbox = ({
  id = "checkboxId",
  name = "checkboxName",
  checked = false,
  onChange = () => {},
  disabled = false,
  hasDarkBorder = false,
  className = "",
  labelClassName = "",
  children,
}: ControlledCheckboxProps) => (
  <div className={cx(styles.checkboxWrapper, className)}>
    <label className={styles.checkboxAndLabel} htmlFor={id}>
      <div
        className={cx(styles.checkbox, {
          [styles.disabledCheckbox]: disabled,
          [styles.darkBorder]: hasDarkBorder,
        })}
      >
        <input
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          type="checkbox"
          onChange={onChange}
        />
        <div className={styles.checkMark} />
        <div className={styles.checkboxCheckedBackground} />
      </div>
      {children && (
        <div className={cx(styles.checkboxLabel, { labelClassName })}>
          {children}
        </div>
      )}
    </label>
  </div>
);

export default ControlledCheckbox;
