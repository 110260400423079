/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import { MouseEventHandler } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon, CloseIcon } from "../../../../assets/icons";
import { Button, Col, Input, Row, Title } from "../../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateEmail } from "../../auth.actions";
import { myProfileSelector } from "../../auth.selectors";
import styles from "../MyProfileForms.module.scss";

type EmailFormProps = {
  isEditing?: boolean;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: Function;
};

const EmailForm = ({
  isEditing = false,
  onEdit = () => {},
  onCancel = () => {},
}: EmailFormProps) => {
  const myProfile = useAppSelector(myProfileSelector);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newEmail: myProfile?.contact?.email ?? "",
    },
    shouldUnregister: true,
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onEmailSubmit = (data) => {
    dispatch(
      updateEmail({
        data: { newEmail: data.newEmail },
        callback: onCancel,
        setError,
      })
    );
  };

  return (
    <Col span={12}>
      <Title level={3}>
        Informations de connexion &nbsp;<span className={styles.redDot}>*</span>
      </Title>
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <Row justify="space-between" wrap={false}>
          <Col className={styles.formRowSpaceBottom} span={7} xl={9}>
            <Input
              label="Email"
              readOnly={!isEditing}
              {...register("newEmail", {
                required: true,
              })}
              error={
                (errors.newEmail?.type === "required" && "E-mail est requis") ||
                (errors.newEmail?.type === "emailTaken" && "L'e-mail est pris")
              }
            />
          </Col>
          {!isEditing ? (
            <Button className={styles.phoneEditButton} onClick={onEdit}>
              Éditer
            </Button>
          ) : (
            <Row className={styles.editButtons}>
              <Button
                className={styles.closeIcon}
                type="button"
                onClick={() => {
                  reset();
                  onCancel();
                }}
              >
                <CloseIcon />
              </Button>
              <Button className={styles.checkIcon} type="submit">
                <CheckIcon />
              </Button>
            </Row>
          )}
        </Row>
      </form>
    </Col>
  );
};

export default EmailForm;
