/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios from "./http";

const apiMeterReadingsSuffix = "/info";

export const fetchMeterReadingsApi = (userId) =>
  axios({
    method: "GET",
    url: `${apiMeterReadingsSuffix}/meters/${userId}`,
  });

export const fetchCountersApi = (userId, params) =>
  axios({
    method: "GET",
    url: `${apiMeterReadingsSuffix}/cadrans/${userId}`,
    params,
  });

export const fetchMeterReadingsAddressesApi = (userId) =>
  axios({
    method: "GET",
    url: `${apiMeterReadingsSuffix}/meteraddress/${userId}`,
  });

export const postCounterApi = (userId, data) =>
  axios({
    method: "POST",
    url: `${apiMeterReadingsSuffix}/meterreading/${userId}`,
    data,
  });

export const fetchMeterReadingsHistoryApi = (userId, data) =>
  axios({
    method: "POST",
    url: `${apiMeterReadingsSuffix}/meterreadings/${userId}`,
    data,
  });
