/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import classNames from "classnames/bind";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { CloseIcon, InfoIcon } from "../../../assets/icons";
import styles from "../input/Input.module.scss";
import Underlay from "../underlay/Underlay";

const cx = classNames.bind(styles);

type ControlledInputProps = {
  type?: "text" | "email" | "password" | "number" | "date";
  name?: string;
  value?: string | number;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  isLabelBolded?: boolean;
  placeholder?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
  min?: string | number;
  max?: string | number;
  debounceTime?: number;
  error?: string | boolean;
  info?: string;
  className?: string;
};

const ControlledInput = React.forwardRef<
  HTMLInputElement,
  ControlledInputProps
>(
  (
    {
      type = "text",
      name = "inputName",
      value = "",
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      placeholder = "",
      prefix = null,
      suffix = null,
      required = false,
      disabled = false,
      readOnly = false,
      isLabelBolded = false,
      autoComplete = "on",
      min = "",
      max = "",
      debounceTime = 0,
      error,
      info,
      className = "",
      label,
    }: ControlledInputProps,
    forwardedRef
  ) => {
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const infoMessageRef: any = useRef();
    const allowNumbersOnlyForNumberInput = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();

    const handleOnChange = (event) => {
      clearTimeout(debounceTimeout.current as NodeJS.Timeout);
      debounceTimeout.current = setTimeout(() => {
        onChange(event);
      }, debounceTime);
    };

    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessageUpsideDown, setInfoMessageUpsideDown] = useState(false);

    useEffect(() => {
      if (showInfoMessage) {
        if (infoMessageRef.current.getBoundingClientRect()?.y <= 0) {
          setInfoMessageUpsideDown(true);
        } else {
          setInfoMessageUpsideDown(false);
        }
      } else {
        setInfoMessageUpsideDown(false);
      }
    }, [showInfoMessage]);

    return (
      <div className={cx(styles.inputWrapper)}>
        {label && (
          <div
            className={cx(styles.inputLabel, {
              [styles.inputLabelBold]: isLabelBolded,
            })}
          >
            {label}
            {required && "*"}
          </div>
        )}
        {prefix && <div className={styles.prefixIcon}>{prefix}</div>}
        <input
          autoComplete={autoComplete}
          className={cx(
            styles.input,
            {
              [styles.inputWithPrefixIcon]: prefix,
              [styles.inputWithSuffixIcon]: suffix,
              [styles.inputWithError]: error,
              [styles.inputDisabled]: disabled && !readOnly,
              [styles.inputReadOnly]: readOnly,
              [styles.inputWithInfoIcon]: info,
            },
            className
          )}
          disabled={disabled || readOnly}
          max={max}
          min={type === "date" ? "2023.01.01" : min}
          name={name}
          placeholder={placeholder}
          ref={forwardedRef}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={debounceTime ? handleOnChange : onChange}
          onFocus={onFocus}
          onKeyDown={
            type === "number" ? allowNumbersOnlyForNumberInput : () => {}
          }
          {...(type === "password" && { onPaste: (e) => e.preventDefault() })}
        />
        {suffix && <div className={styles.suffixIcon}>{suffix}</div>}
        {info && (
          <InfoIcon
            className={styles.infoIcon}
            onClick={() => setShowInfoMessage(true)}
          />
        )}
        {showInfoMessage && (
          <>
            <div
              className={cx(styles.infoMessage, {
                [styles.infoMessageUpsideDown]: infoMessageUpsideDown,
              })}
              ref={infoMessageRef}
            >
              <CloseIcon
                className={styles.closeInfoMessageIcon}
                onClick={() => setShowInfoMessage(false)}
              />
              {info}
              <div
                className={cx(styles.infoMessageTriangle, {
                  [styles.infoMessageTriangleUpsideDown]: infoMessageUpsideDown,
                })}
              />
            </div>
            <Underlay opacity={0.1} onClick={() => setShowInfoMessage(false)} />
          </>
        )}
        {error && <div className={styles.inputErrorMessage}>{error}</div>}
      </div>
    );
  }
);

export default ControlledInput;
