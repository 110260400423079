/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { PAGES, SPECIAL_CHARACTERS } from "../../../common/constants/common";
import {
  Button,
  Col,
  Input,
  Link,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { signUp } from "../public.actions";
import { isSubmittingPublicSelector } from "../public.selectors";
import { signUpFormData } from "../public.types";
import styles from "./SignUp.module.scss";

const SignUp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isSubmitting = useAppSelector(isSubmittingPublicSelector);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      name: "",
      email: "",
      password: "",
      retypePassword: "",
    },
    shouldUnregister: true,
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onSignUp = (data: signUpFormData) => {
    const { retypePassword, ...dataForApi } = data;
    dispatch(signUp(dataForApi));
  };

  return (
    <Row className={styles.signUp} direction="column">
      <Col lg={6} md={8} span={10} xl={3}>
        <Title align="center" level={1}>
          Créer mon compte
        </Title>
        <Text align="center" size="large">
          Créer mon Espace Client en quelques clics
          <br />
          et simplifier la gestion de mes offres SIE / TVT.
        </Text>
        <Separator size="small" />
        <Text align="center" className={styles.requiredFieldsText}>
          Tous les champs sont obligatoires.
        </Text>
        <Separator size="large" />
      </Col>
      <Col lg={4} md={6} span={10} xl={3} xxl={2}>
        <form onSubmit={handleSubmit(onSignUp)}>
          <Input
            {...register("userId", { required: true })}
            isLabelBolded
            error={errors.userId && "Le numéro de client est requis"}
            info="Vous pouvez retrouver votre numéro client sur vos factures."
            label="Numéro client"
            type="number"
          />
          <Separator />
          <Input
            {...register("name", { required: true })}
            isLabelBolded
            error={
              errors.name &&
              "Le nom de famille ou le nom de l'entreprise est requis"
            }
            info="Merci d'indiquer votre nom de famille ou raison sociale de la même façon qu'indiqué sur vos factures."
            label="Nom de famille ou raison sociale"
            type="text"
          />
          <Separator />
          <Input
            {...register("email", { required: true })}
            isLabelBolded
            error={errors.email && "Email est requis"}
            label="Email"
            type="email"
          />
          <Separator />
          <Input
            {...register("password", {
              required: true,
              minLength: 8,
              validate: {
                hasLowerCase: (value) => /[a-z]+/.test(value),
                hasUpperCase: (value) => /[A-Z]+/.test(value),
                hasNumber: (value) => /[0-9]+/.test(value),
                hasSpecialCharacter: (value) =>
                  SPECIAL_CHARACTERS.some((specialCharacter) =>
                    value.includes(specialCharacter)
                  ),
              },
            })}
            isLabelBolded
            error={
              (errors.password?.type === "required" && "Mot de passe requis") ||
              (errors.password?.type === "minLength" &&
                "Votre mot de passe ne répond pas aux exigences de sécurité demandées: être composé au minimum de 8 caractères.") ||
              (errors.password?.type === "hasLowerCase" &&
                "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre minuscule latine (de a à z).") ||
              (errors.password?.type === "hasUpperCase" &&
                "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre majuscule latine (de A à Z).") ||
              (errors.password?.type === "hasNumber" &&
                "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un chiffre (de 0 à 9).") ||
              (errors.password?.type === "hasSpecialCharacter" &&
                "Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un caractère spécial (exemple : ?, !).")
            }
            label="Mot de passe"
            type="password"
          />
          <Separator />
          <Input
            {...register("retypePassword", {
              required: true,
              validate: (value) => value === password.current,
            })}
            isLabelBolded
            error={
              (errors?.retypePassword?.type === "required" &&
                "Veuillez confirmer le mot de passe") ||
              (errors?.retypePassword?.type === "validate" &&
                "Les mots de passe ne correspondent pas")
            }
            label="Confirmer mot de passe"
            type="password"
          />
          <Separator />
          <Row>
            <Button
              buttonStyle="secondaryButton"
              className={styles.signUpSecondaryButton}
              type="button"
              onClick={() => history.push(PAGES.SIGN_IN)}
            >
              Annuler
            </Button>
            <Button
              className={styles.signUpButton}
              loading={isSubmitting}
              type="submit"
            >
              Valider
            </Button>
          </Row>
        </form>
        <Separator size="large" />
      </Col>
      <Col className={styles.formFooter} span={12}>
        <Row>
          <Col lg={6} md={8} span={10} xl={3}>
            <Text size="large">
              Données personnelles et conditions générales d&apos;utilisation
            </Text>
            <Separator size="small" />
            <Text className={styles.personalDataText} size="small">
              Pour plus d’informations sur le traitement de vos données
              personnelles, nous vous invitons à vous référer à&nbsp;
              <Link
                className={styles.privacyPolicyLink}
                target="_blank"
                to={PAGES.DATA_PROTECTION}
              >
                notre charte de protection des données personnelles.
              </Link>
            </Text>
            <Separator size="small" />
            <Text isLight>
              L&rsquo;utilisation du portail client vaut comme acceptation des
              <Link
                className={styles.privacyPolicyLink}
                target="_blank"
                to={PAGES.TERMS_OF_SERVICE}
              >
                &nbsp;conditions générales d’utilisation.
              </Link>
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignUp;
