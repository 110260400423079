/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./Checkbox.module.scss";

const cx = classNames.bind(styles);

type CheckboxProps = {
  id: string;
  name?: string;
  defaultValue?: boolean;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasDarkBorder?: boolean;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id = "checkboxId",
      name = "checkboxName",
      defaultValue = false,
      onChange = () => {},
      disabled = false,
      hasDarkBorder = false,
      className = "",
      labelClassName = "",
      children,
    }: CheckboxProps,
    forwardedRef
  ) => (
    <div className={cx(styles.checkboxWrapper, className)}>
      <label className={styles.checkboxAndLabel} htmlFor={id}>
        <div
          className={cx(styles.checkbox, {
            [styles.disabledCheckbox]: disabled,
            [styles.darkBorder]: hasDarkBorder,
          })}
        >
          <input
            defaultChecked={defaultValue}
            disabled={disabled}
            id={id}
            name={name}
            ref={forwardedRef}
            type="checkbox"
            onChange={onChange}
          />
          <div className={styles.checkMark} />
          <div className={styles.checkboxCheckedBackground} />
        </div>
        {children && (
          <div className={cx(styles.checkboxLabel, { labelClassName })}>
            {children}
          </div>
        )}
      </label>
    </div>
  )
);

export default Checkbox;
