/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { Row, Separator, Text } from "../../../components/ui";
import styles from "./DataProtection.module.scss";
import Section from "./Section";

const DataProtection = () => (
  <Row align="start" className={styles.DataProtection} direction="column">
    <Section
      paragraphs={[
        "SIE et TVT Services attachent une grande importance au respect de la vie privée, et en particulier à la protection de vos données personnelles. Ces données sont donc traitées avec le plus grand soin conformément aux dispositions de la loi fédérale sur la protection des données (LPD) et à l’ordonnance relative à la loi fédérale sur la protection des données (OLPD). Cette charte est appliquée par toutes les entités de SIE et TVT Services.",
      ]}
      title="Charte de protection des données"
    />
    <Section
      paragraphs={[
        "Nous collectons, enregistrons et traitons les données personnelles qui nous sont communiquées dans le cadre de notre relation contractuelle (nom, prénom, adresse, numéros de téléphone, courriel, date de naissance, informations nécessaires à la relation contractuelle ou pour répondre à une demande, etc.).",
        "Lorsque vous nous communiquez des données personnelles, que ce soit sur nos services en ligne, par téléphone ou par écrit, vous acceptez que ces informations puissent être stockées, traitées et transmises pour les finalités annoncées.",
        "Toutefois, les données personnelles transmises à SIE en tant que gestionnaire de réseau de distribution sont traitées de façon strictement confidentielles conformément à l’art. 10 de la Loi sur l’approvisionnement en électricité.",
      ]}
      subtitle="a. Quelles données personnelles font l’objet d’un traitement?"
      title="1. Utilisation des données personnelles"
    />
    <Section
      paragraphs={[
        "Nous pouvons utiliser vos données personnelles pour communiquer avec vous dans le cadre de notre relation contractuelle, pour établir des factures, pour garantir une qualité de service élevée, pour gérer nos relations clients et à des fins marketing pour vous proposer, par exemple, des offres et des informations personnalisées.",
      ]}
      subtitle="b. Dans quel but utilisons-nous vos données personnelles?"
      title=""
    />
    <Section
      paragraphs={[
        "Les données personnelles nécessaires aux prestations énergétiques sont utilisées par SIE et celles nécessaires aux prestations multimédia sont utilisées par TVT. Elles peuvent également être transmises à des tiers si cela est nécessaire afin de permettre le traitement de la relation contractuelle, qui les traiteront dans le respect de la législation sur la protection des données.",
      ]}
      subtitle="c. Par qui et où sont traitées les données personnelles?"
      title=""
    />
    <Section
      paragraphs={[
        "Nous prenons les dispositions techniques et organisationnelles nécessaires pour protéger vos données personnelles contre tout traitement non autorisé, ainsi que pour en assurer la confidentialité, la disponibilité et l’intégrité. Ces mesures ont notamment pour objectif de protéger les données personnelles qui se trouvent dans notre système informatique contre les risques suivants: suppression accidentelle ou non autorisée, perte accidentelle, erreur technique, falsification, vol, utilisation illicite, modification, copie, accès ou toute autre action non autorisée. Nous veillons également à ce que vos données personnelles bénéficient d’une protection et d’une sécurité appropriées lorsque nous les transmettons à des sociétés liées ou à des fournisseurs de services domiciliés en Suisse ou à l’étranger.",
        "Nous attirons votre attention sur le fait qu’Internet n’est pas un média sécurisé et que des tiers non autorisés peuvent avoir accès aux données transmises dans cet environnement. Cela peut conduire à la publication ou la modification desdites données, ou encore à des problèmes techniques. Malgré d’importantes mesures de sécurité aussi bien techniques qu’organisationnelles, la perte des données personnelles, leur interception ou leur manipulation par une personne non autorisée ne peut être exclue.",
      ]}
      title="2. Sécurité des données personnelles"
    />
    <Section
      paragraphs={[
        "La loi vous confère un droit d’accès aux données personnelles vous concernant, ainsi qu’un droit de rectification, de suppression et de blocage. Sur simple demande de votre part adressée à info@sie.ch, vous pourrez exercer ces droits.",
        "Vous pouvez à tout moment faire opposition à la réception de publicités et au traitement de vos données à des fins de marketing et de publicité, avec effet sur le futur, en écrivant à : info@sie.ch ou SIE SA, Service clients, Espace Conseils Rue Neuve 5, Case postale 1020 Renens",
      ]}
      title="3. Vos droits"
    />
    <Section
      paragraphs={[
        "La présente déclaration entre en vigueur à compter de janvier 2023. Nous nous réservons la possibilité de la modifier. Pour vérifier les mises à jour, nous vous invitons à consulter régulièrement cette page.",
      ]}
      title="4. Modifications de la présente déclaration sur la protection des données."
    />
    <Section
      paragraphs={[
        "Pour toutes questions ou commentaires, vous pouvez vous adresser à info@sie.ch.",
      ]}
      title="5. Questions et commentaires"
    />
    <Separator />
    <Text>2022 © SIE SA & TVT Services SA</Text>
  </Row>
);

export default DataProtection;
