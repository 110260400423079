/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Textarea.module.scss";

const cx = classNames.bind(styles);

type TextareaProps = {
  name?: string;
  defaultValue?: string | number;
  rows?: number;
  // eslint-disable-next-line
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  // eslint-disable-next-line
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  // eslint-disable-next-line
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: ReactNode;
  isLabelBolded?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  className?: string;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      name = "textareaName",
      defaultValue = "",
      rows = 15,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      disabled = false,
      error,
      className = "",
      label,
      isLabelBolded = false,
    }: TextareaProps,
    forwardedRef
  ) => (
    <>
      {label && (
        <div
          className={cx(styles.textareaLabel, {
            [styles.textareaLabelBold]: isLabelBolded,
          })}
        >
          {label}
        </div>
      )}
      <textarea
        className={cx(
          styles.textarea,
          {
            [styles.textareaWithError]: error,
            [styles.textareaDisabled]: disabled,
          },
          className
        )}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        ref={forwardedRef}
        rows={rows}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
      {error && <div className={styles.textareaErrorMessage}>{error}</div>}
    </>
  )
);

export default Textarea;
