/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import styles from "./Loader.module.scss";

type LoaderProps = {
  type?: "auth" | "page" | "content";
  smallSize?: boolean;
};

const cx = classNames.bind(styles);

const Loader = ({ type = "page", smallSize = false }: LoaderProps) => (
  <div
    className={cx({
      [styles.authLoader]: type === "auth",
      [styles.pageLoader]: type === "page",
      [styles.contentLoader]: type === "content",
    })}
  >
    <div
      className={cx(styles.redDualBallAnimation, {
        [styles.smallSize]: smallSize,
      })}
    />
    <div
      className={cx(styles.blueDualBallAnimation, {
        [styles.smallSize]: smallSize,
      })}
    />
  </div>
);

export default Loader;
