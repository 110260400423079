/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  forgottenPasswordApi,
  resetPasswordApi,
  signInApi,
  signUpApi,
} from "../../api/public.api";
import { PAGES, SESSION_STORAGE } from "../../common/constants/common";
import { AppDispatch } from "../../redux/store";
import { history, toastError, toastSuccess } from "../../services";
import * as actionTypes from "./public.actionTypes";
import {
  forgottenPasswordRequestData,
  resetPasswordRequestData,
  signInRequestData,
  signInResponseData,
  signUpRequestData,
} from "./public.types";

export const signUp =
  (data: signUpRequestData) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: actionTypes.SIGN_UP_START });

      await signUpApi(data);

      dispatch({ type: actionTypes.SIGN_UP_SUCCESS });

      history.push(PAGES.SIGN_UP_CONFIRMATION);
    } catch (error) {
      dispatch({ type: actionTypes.SIGN_UP_FAILURE });

      const currentURL = window.location.href;

      if (currentURL.includes("emailconfirm/confirmemail?")) {
        toastSuccess({
          message: "Votre compte a bien été activé.",
        });
      }

      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          if (
            (<any>error.response?.data)?.error.message === "User Already Exists"
          ) {
            toastError({
              message:
                "Un compte est déjà existant pour ce numéro client. Merci de vous connecter en utilisant votre adresse email et mot de passe existants.",
              timeout: false,
            });
          } else {
            toastError({
              message:
                "Votre compte client ne peut être créé, car votre numéro client ne correspond pas à votre nom de famille ou raison sociale. Merci de vérifier les données que vous avez entrées. Si cette erreur se reproduit, merci de nous contacter par téléphone au 021 631 50 00.",
              timeout: false,
            });
          }
        }
        if (error.response?.status === 423) {
          history.push(PAGES.SIGN_IN);
          toastError({
            message:
              "Vous avez essayé de vous identifier, ou enregistrer, plus de 5 fois sans succès. Merci de patienter 24 heures avant de pouvoir réessayer.",
            timeout: false,
          });
        }
        if (error.response?.status === 429) {
          toastError({
            message:
              "Trop de tentatives de connexion. Merci de patienter 5 minutes avant de réessayer.",
          });
        }
      }
    }
  };

export const signIn =
  (
    requestData: signInRequestData,
    setFieldError: Function,
    resetForm: Function
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: actionTypes.SIGN_IN_START });

      const response: AxiosResponse<signInResponseData> = await signInApi(
        requestData
      );

      dispatch({ type: actionTypes.SIGN_IN_SUCCESS });

      sessionStorage.setItem(
        SESSION_STORAGE.ACCESS_TOKEN,
        response.data.data.token
      );
      sessionStorage.setItem(
        SESSION_STORAGE.USER_ID,
        response.data.data.userId.toString()
      );
      sessionStorage.setItem(SESSION_STORAGE.ROLE, response.data.data.role);
      // if user inactivity toast exists, remove it after successful sign in
      toast.dismiss();
      history.push(PAGES.DASHBOARD);
    } catch (error) {
      dispatch({ type: actionTypes.SIGN_IN_FAILURE });
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          if (
            (<any>error.response?.data)?.error?.message === "Issues Logging in"
          ) {
            setFieldError("email", {
              type: "incorrectEmail",
              message: "Email ou mot de passe incorrect",
            });
            setFieldError("password", {
              type: "incorrectPassword",
              message: "Email ou mot de passe incorrect",
            });
          } else {
            toastError({
              message: (<any>error.response?.data)?.error?.message,
            });
          }
        }
        if (error.response?.status === 423) {
          toastError({
            message:
              "Vous avez essayé de vous identifier, ou enregistrer, plus de 5 fois sans succès. Merci de patienter 24 heures avant de pouvoir réessayer.",
            timeout: false,
          });
          if (resetForm) {
            resetForm();
          }
        }
        if (error.response?.status === 429) {
          toastError({
            message:
              "Trop de tentatives de connexion. Merci de patienter 5 minutes avant de réessayer.",
          });
        }
      }
    }
  };

export const forgottenPassword =
  (data: forgottenPasswordRequestData, setFieldError: Function) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: actionTypes.FORGOTTEN_PASSWORD_START });

      await forgottenPasswordApi(data);

      toastSuccess({
        message:
          "Veuillez vérifier votre e-mail et cliquez sur le lien pour réinitialiser votre mot de passe",
        timeout: false,
      });
      dispatch({ type: actionTypes.FORGOTTEN_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({ type: actionTypes.FORGOTTEN_PASSWORD_FAILURE });
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          setFieldError("email", {
            type: "incorrectEmail",
            message: "l'e-mail n'existe pas",
          });
        }
      }
    }
  };

export const resetPassword =
  (data: resetPasswordRequestData, setFieldError: Function) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: actionTypes.RESET_PASSWORD_START });

      await resetPasswordApi(data);

      toastSuccess({ message: "Modification effectuée avec succès." });
      dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });

      history.push(PAGES.SIGN_IN);
    } catch (error: any) {
      if (error.response?.status === 201) {
        toastSuccess({ message: "Modification effectuée avec succès." });
        dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
        return;
      }
      dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE });
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          setFieldError("newPassword", {
            type: "sameAsOldPassword",
            message:
              "Votre nouveau mot de passe ne peut pas être le même que votre ancien mot de passe",
          });
        }
        if (error.response?.status === 404) {
          toastError({
            message: "l'Email n'existe pas",
          });
        }
      }
    }
  };
