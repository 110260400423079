/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
export const INTERNET_COAXAL = {
  OPEN: "Ouvert",
  LEADED: "Plombé",
  UNAVAILABLE: "Indisponible",
};

export const INTERNET_OPTIQ = {
  ENABLED: "Activé",
  AVAILABLE: "Disponible",
  UNAVAILABLE: "Indisponible",
};
