/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { IContactData } from "../modules/electricityAndMultimedia/contactAndForms/service/contactAndForms.types";
import axios from "./http";

const contactAndFormsApiSuffix = "/info";

export const contactAndFormsApi = (userId: string, data: IContactData) =>
  axios({
    method: "POST",
    url: `${contactAndFormsApiSuffix}/form/${userId}`,
    data,
  });
