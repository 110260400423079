/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */

export const FETCH_POSTAL_CODES_START = "FORMS::FETCH_POSTAL_CODES_START";
export const FETCH_POSTAL_CODES_SUCCESS = "FORMS::FETCH_POSTAL_CODES_SUCCESS";
export const FETCH_POSTAL_CODES_FAILURE = "FORMS::FETCH_POSTAL_CODES_FAILURE";
