/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import { MouseEventHandler } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon, CloseIcon } from "../../../../assets/icons";
import { Button, Col, Input, Row } from "../../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateMobilePhone } from "../../auth.actions";
import { myProfileSelector } from "../../auth.selectors";
import styles from "../MyProfileForms.module.scss";

type MobilePhoneFormProps = {
  isEditing?: boolean;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: Function;
};

const MobilePhoneForm = ({
  isEditing = false,
  onEdit = () => {},
  onCancel = () => {},
}: MobilePhoneFormProps) => {
  const myProfile = useAppSelector(myProfileSelector);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobilePhoneNumber:
        myProfile?.contact?.mobilePhoneNumber?.replaceAll(" ", "") ?? "",
    },
    shouldUnregister: true,
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onMobilePhoneSubmit = (data) => {
    if (!data.mobilePhoneNumber && !myProfile?.contact?.homePhoneNumber) {
      setError("mobilePhoneNumber", {
        type: "homePhoneEmpty",
        message:
          "Merci de compléter au moins l’un des deux numéros de téléphone.",
      });
      return;
    }
    dispatch(
      updateMobilePhone({
        data: {
          phoneNumber: data.mobilePhoneNumber,
          communicationId: myProfile.contact.mobilePhoneNumberId,
        },
        callback: onCancel,
      })
    );
  };

  return (
    <Col md={5} span={12}>
      <form onSubmit={handleSubmit(onMobilePhoneSubmit)}>
        <Row justify="space-between" wrap={false}>
          <Col className={styles.formRowSpaceBottom} span={7} xl={9}>
            <Input
              label="Téléphone mobile"
              placeholder="+41xxxxxxxxx"
              readOnly={!isEditing}
              {...register("mobilePhoneNumber", {
                required: false,
                pattern: /^\+?[0-9]+$/,
              })}
              error={
                (errors.mobilePhoneNumber?.type === "required" &&
                  "Un téléphone portable est requis") ||
                (errors.mobilePhoneNumber?.type === "pattern" &&
                  'Seuls les chiffre et le signe "+" sont autorisés') ||
                (errors.mobilePhoneNumber?.type === "homePhoneEmpty" &&
                  "Merci de compléter au moins l’un des deux numéros de téléphone.")
              }
            />
          </Col>
          {!isEditing ? (
            <Button className={styles.phoneEditButton} onClick={onEdit}>
              Éditer
            </Button>
          ) : (
            <Row className={styles.editButtons}>
              <Button
                className={styles.closeIcon}
                type="button"
                onClick={() => {
                  onCancel();
                  reset();
                }}
              >
                <CloseIcon />
              </Button>
              <Button className={styles.checkIcon} type="submit">
                <CheckIcon />
              </Button>
            </Row>
          )}
        </Row>
      </form>
    </Col>
  );
};

export default MobilePhoneForm;
