/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const FETCH_DASHBOARD_DATA_START =
  "DASHBOARD::FETCH_DASHBOARD_DATA_START";
export const FETCH_DASHBOARD_DATA_SUCCESS =
  "DASHBOARD::FETCH_DASHBOARD_DATA_SUCCESS";
export const FETCH_DASHBOARD_DATA_FAILURE =
  "DASHBOARD::FETCH_DASHBOARD_DATA_FAILURE";

export const FETCH_LAST_INVOICE_AND_ELECTRICITY_CONTRACT_SUCCESS =
  "DASHBOARD::FETCH_LAST_INVOICE_AND_ELECTRICITY_CONTRACT_SUCCESS";

export const SUBSCRIBE_TO_NEWSLETTER_START =
  "DASHBOARD::SUBSCRIBE_TO_NEWSLETTER_START";
export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS =
  "DASHBOARD::SUBSCRIBE_TO_NEWSLETTER_SUCCESS";
export const SUBSCRIBE_TO_NEWSLETTER_FAILURE =
  "DASHBOARD::SUBSCRIBE_TO_NEWSLETTER_FAILURE";
