/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import classNames from "classnames/bind";
import { useState } from "react";
import { SmallArrowIcon } from "../../../assets/icons";
import MultimediaCarouselImage1 from "../../../assets/images/multimedia-carousel-1.png";
import MultimediaCarouselImage2 from "../../../assets/images/multimedia-carousel-2.png";
import MultimediaCarouselImage3 from "../../../assets/images/multimedia-carousel-3.png";
import MultimediaCarouselImage4 from "../../../assets/images/multimedia-carousel-4.png";
import MultimediaCarouselImage5 from "../../../assets/images/multimedia-carousel-5.png";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Button,
  Col,
  Link,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import styles from "./MyMultimediaOfferCarousel.module.scss";

const cx = classNames.bind(styles);

const MyMultimediaOfferCarousel = () => {
  const carouselItems = [
    {
      id: 1,
      title: "Regarder la TV n’a jamais été aussi simple.",
      description:
        "Installation facile, jusqu’à six profils, Replay et commande vocale.",
      image: MultimediaCarouselImage1,
      url: "https://form.jotform.com/221312345864351",
    },
    {
      id: 2,
      title: "Internet ultra-rapide !",
      description:
        "Dites adieu aux problèmes de connexion avec le réseau fibré de TVT.",
      image: MultimediaCarouselImage2,
      url: "https://form.jotform.com/221312345864351",
    },
    {
      id: 3,
      title: "Pour une expérience de jeu optimale !",
      description: "Optez pour le réseau fibre optique de TVT.",
      image: MultimediaCarouselImage3,
      url: "https://form.jotform.com/221312345864351",
    },
    {
      id: 4,
      title: "Toujours au premier rang.",
      description:
        "Des moments inoubliables. En exclusivité pour toutes les clientes et tous les clients Sunrise / UPC.",
      image: MultimediaCarouselImage4,
      url: "https://www.sunrise.ch/fr/moments",
    },
    {
      id: 5,
      title: "Surfez au débit mobile maximal !",
      description: "Internet mobile illimité en Suisse et en Europe.",
      image: MultimediaCarouselImage5,
      url: "https://form.jotform.com/221313918749360",
    },
  ];

  const [carouselStartPosition, setCarouselStartPosition] = useState(0);

  return (
    <Row ignorePageContentPadding align="start" direction="column">
      <Col hasPageContentOffset span={12}>
        <Title className={styles.carouselTitle} level={2}>
          Offres de nos partenaires
        </Title>
        <Separator />
      </Col>
      <Col hasPageContentOffset span={12}>
        <div className={styles.carouselItemsWrapper}>
          <div
            className={cx(styles.carouselItems, [
              styles[`carouselPosition-${carouselStartPosition}`],
            ])}
          >
            {carouselItems.map((carouselItem) => (
              <Link
                className={styles.carouselItem}
                href={carouselItem.url}
                key={carouselItem.id}
              >
                <img
                  alt="Multimedia offer"
                  className={styles.carouselItemImage}
                  src={carouselItem.image}
                />
                <Title
                  align="center"
                  className={styles.carouselItemTitle}
                  level={2}
                >
                  {carouselItem.title}
                </Title>
                <Text align="center" className={styles.carouselItemText}>
                  {carouselItem.description}
                </Text>
              </Link>
            ))}
          </div>
          {carouselStartPosition > 0 && (
            <Button
              aria-label="next"
              className={styles.carouselButtonPrevious}
              type="button"
              onClick={() => {
                trackClickHandler();
                setCarouselStartPosition(carouselStartPosition - 1);
              }}
            >
              <span className={styles.buttonPreviousArrow}>
                <SmallArrowIcon />
              </span>
            </Button>
          )}
          <Button
            aria-label="next"
            className={cx(styles.carouselButtonNext, {
              [styles.carouselButtonNextHidden]:
                carouselStartPosition >= carouselItems.length - 2,
              [styles.carouselButtonNextHiddenForMobile]:
                carouselStartPosition === carouselItems.length - 1,
            })}
            type="button"
            onClick={() => {
              trackClickHandler();
              setCarouselStartPosition(carouselStartPosition + 1);
            }}
          >
            <span className={styles.buttonNextArrow}>
              <SmallArrowIcon />
            </span>
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default MyMultimediaOfferCarousel;
