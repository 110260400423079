/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { AppState } from "../../redux/store";

export const isFetchingAdminSelector = (state: AppState) =>
  state.admin["isFetching"];
export const isSubmittingAdminSelector = (state: AppState) =>
  state.admin["isSubmitting"];
export const isAdminConnectionCheckedSelector = (state: AppState) =>
  state.admin["isAdminConnectionChecked"];
export const isFetchingUserSelector = (state: AppState) =>
  state.admin["isFetchingUser"];
export const userSelector = (state: AppState) => state.admin["user"];
export const isResendingVerificationEmailSelector = (state: AppState) =>
  state.admin["isResendingVerificationEmail"];
